import React from 'react';
import { NavLink } from 'react-router-dom';
import style from './navItem.module.css';
import NavItemHeader from './NavItemHeader.jsx';

import { Desktop } from '../../../utils/mediaQueries';

const NavItem = props => {
  const { label, Icon, to, children } = props.item;

  if (children) {
    return <NavItemHeader item={props.item} />;
  }

  return (
    <NavLink
      exact={true}
      to={to}
      className={style.navItem}
      // activeClassName={style.activeNavItem}
      activeclassname="activeNavItem"
    >
      {Icon}
      {/* <i className={`${Icon} style.navIcon`}></i> */}
      {/* <Icon className={style.navIcon} /> */}
      <Desktop>
        <span className={style.navLabel}>{label}</span>
      </Desktop>
    </NavLink>
  );
};

export default NavItem;

