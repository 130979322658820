import classNames from 'classnames';
import React from 'react';

export const activeBodyTemplate = rowData => {
  if (rowData.is_active === true) {
    return (
      <React.Fragment>
        <span
          className={classNames(
            'priority-badge',
            'status-' + rowData.is_active
          )}
        >
          ACTIVE
        </span>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <span
          className={classNames(
            'priority-badge',
            'status-' + rowData.is_active
          )}
        >
          INACTIVE
        </span>
      </React.Fragment>
    );
  }
};

