import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import './TimelineDemo.css';
import {
  fetchAssetActivity,
  fetchActivities,
} from '../../../../shared/redux/actions/activityActions';
import Moment from 'moment';
import moment from 'moment';

class AssetActivity extends Component {
  constructor(props) {
    super(props);
    this.events1 = [
      {
        status: 'Ordered',
        date: '15/10/2020 10:30',
        icon: 'pi pi-shopping-cart text-xl',
        color: '#9C27B0',
        image: 'game-controller.jpg',
      },
      {
        status: 'Processing',
        date: '15/10/2020 14:00',
        icon: 'pi pi-cog text-xl',
        color: '#673AB7',
      },
      {
        status: 'Shipped',
        date: '15/10/2020 16:15',
        icon: 'pi pi-shopping-cart text-xl',
        color: '#FF9800',
      },
      {
        status: 'Delivered',
        date: '16/10/2020 10:00',
        icon: 'pi pi-check',
        color: '#607D8B',
      },
    ];
  }

  findkey(dataa) {
    dataa.forEach(element => {
      let msg = element.message;

      let meta1 = Object.keys(element.meta);
      let meta2 = Object.values(element.meta);
      let nwmsq = msg.split(meta1[0]).join(meta2[0]);
      let nsq = nwmsq.split(meta1[2]).join(meta2[2]);
      let mk = nsq.replace(/[{}]/g, '');

      console.log(mk);
    });
  }

  componentDidMount() {
    this.props.fetchActivities();
    this.props.fetchAssetActivity(localStorage.getItem('assetid'));
  }

  render() {
    const customizedMarker = item => {
      return (
        <span
          className="custom-marker shadow-1"
          style={{
            // backgroundColor: item.color,
            backgroundColor: 'rgb(243 220 185)',

            borderRadius: '30px',
            width: '2.1rem',
            height: '2rem',
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <i className="pi pi-clock text-xl" />
          {/* <i className={item.icon}></i> */}
        </span>
      );
    };
    const dateBodyTemplate = rowData => {
      return (
        <React.Fragment>
          {Moment(rowData.created).local().format('DD-MM-YYYY')}
        </React.Fragment>
      );
    };

    const msgBodyTemplate = item => {
      let msg = item.message;
      let meta1 = Object.keys(item.meta);
      let meta2 = Object.values(item.meta);
      let metaDate = moment(item.meta['datetime']).format('DD-MM-YYYY');
      let fSplit = msg.split(meta1[0]).join(meta2[0]);
      let sSplit = fSplit.split('datetime').join(metaDate);
      let fMsg = sSplit.replace(/[{}]/g, '');
      return (
        <React.Fragment>
          <div className="capitalize text-base">{fMsg}</div>
        </React.Fragment>
      );
    };
    const customizedContent = item => {
      return (
        <Card
          className="bg-blue-50"
          title={msgBodyTemplate(item)}
          // subTitle={dateBodyTemplate(item)}
        ></Card>
      );
    };

    const contentLength = this.props.assetActivities.length;

    return (
      <div className="mt-3">
        <div className="p-3">
          {contentLength > 0 ? (
            <Timeline
              value={this.props.assetActivities}
              align="alternate"
              className="customized-timeline"
              marker={customizedMarker}
              content={customizedContent}
            />
          ) : (
            <div class="surface-card shadow-2 border-round p-4">
              <h3 class="text-500  font-medium">No activities for this item</h3>
            </div>
          )}
        </div>
      </div>
    );
  }
}

AssetActivity.propTypes = {};

const mapStateToProps = state => ({
  activities: state.activities.activities,
  assetActivities: state.activities.assetActivities,
});

export default connect(mapStateToProps, {
  fetchActivities,
  fetchAssetActivity,
})(AssetActivity);

