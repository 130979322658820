import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/sidebar/Sidebar';
import Topnav from '../components/topnav/Topnav';

function Layout(props) {
  return (
    <Fragment>
      <div className="flex w-screen ">
        <div className="flex" style={{ width: '18vw' }}>
          <Sidebar />
        </div>
        <div style={{ width: '82vw' }}>
          <Topnav />

          <div className="mt-auto relative">
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Layout;

