import { authActions } from '../actions/types';
import reduxReset from 'redux-reset';
import jwt_decode from 'jwt-decode';

//let user = JSON.parse(localStorage.getItem("user"));

// const initialState = user ? { loggedIn: true, user } : {};
const initialState = {
  user: {},
  role: null,
  isAuthenticated: null,
  user_id: '',
  requestPasswordReset: {},
  passwordReset: {},
  verifieddata: {},
  testingdata: null,
  activateData: {},
  accountType: '',
  loggedIn: null,
  permissions: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.payload.user, // this will show the spinner
        error: false,
      };
    case authActions.LOGIN_SUCCESS:
      localStorage.setItem('login', JSON.stringify(action.payload.user));
      localStorage.setItem(
        'role',
        JSON.stringify(action.payload.user.role.title)
      );
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('user_id', JSON.stringify(action.payload.user.id));
      localStorage.setItem(
        'push_id',
        JSON.stringify(action.payload.user.push_id)
      );

      localStorage.setItem(
        'department_id',
        JSON.stringify(action.payload.user.department_id)
      );
      localStorage.setItem(
        'usertoken',
        JSON.stringify(action.payload.access_token)
      );

      return {
        loggedIn: true,
        user: { ...action.payload.user },
        isAuthenticated: true,
        role: action.payload.user.role.title,
        user_id: action.payload.user.id,
        jwt: jwt_decode(action.payload.access_token),
        permissions: action.payload.user.role.permissions,
        error: false,
      };

    case authActions.LOGIN_ADMIN_SUCCESS:
      localStorage.setItem('login', JSON.stringify(action.payload.user));
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('user_id', JSON.stringify(action.payload.user.id));
      localStorage.setItem(
        'push_id',
        JSON.stringify(action.payload.user.push_id)
      );
      return {
        loggedIn: true,
        user: { ...action.payload.user },
        accountType: action.payload.user.account,
        isAuthenticated: true,
      };

    case authActions.LOGIN_ERROR:
      console.log(action.payload);
      return {
        loggedIn: false,
        error: { ...action.payload },
      };

    case authActions.REQUEST_RESET:
      return { requestPasswordReset: { ...action.payload } };

    case authActions.PASSWORD_RESET:
      return { passwordReset: { ...action.payload } };

    case authActions.VERIFY_EMAIL:
      return { verifieddata: { ...action.payload } };

    case authActions.ACTIVATE_ACCOUNT:
      return { activateData: { ...action.payload } };

    case authActions.LOGOUT:
      return {
        reduxReset,
      };
    default:
      return state;
  }
};

export default authReducer;

