import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { useLocation } from 'react-router-dom';
import { activateAccount } from '../../../../shared/redux/actions/authActions';
import { connect } from 'react-redux';

function EmailVerification(props) {
  const bgImg = process.env.PUBLIC_URL + 'default_bg.jpg';
  const mailImg = process.env.PUBLIC_URL + 'images/mail.svg';
  let location = useLocation();

  const goToLogin = () => {
    window.location.replace('/');
  };
  const activateAccount = () => {
    let token = location.search.split('?token=')[1];
    props.activateAccount(token);
  };
  useEffect(() => {
    activateAccount();
  });

  return (
    <div className="bg-grad w-full h-screen flex">
      <div className=" flex shadow-6 login-box ">
        <div className="w-6 bd-rad bg-white p-2">
          <div className="flex  justify-content-center align-items-center">
            <div className="text-center w-10 surface-100 v-box border-round p-6">
              <div className="py-3">
                <img src={mailImg} alt="mail" className="w-7rem h-7rem " />
              </div>
              <div className="text-2xl py-3">Email Verified successfully</div>
              <div className="field col-12 py-3">
                <Button
                  label="Proceed to LogIn"
                  style={{ background: '#1e3a8a' }}
                  icon="pi pi-user"
                  onClick={() => goToLogin()}
                ></Button>
              </div>
            </div>
          </div>

          <div className="text-xs align-content-center justify-content-center font-italic flex -mt-2">
            <p className="flex">Powered by:</p>
            <div className="flex ">
              <img
                src={process.env.PUBLIC_URL + 'aiti-logo.png'}
                alt="logo"
                // height={50}
                className=" w-6rem h-2rem "
              />
            </div>
          </div>
        </div>
        <div
          className="w-6 p-0 h-full bd-rad  bg-no-repeat bg-cover flex align-items-end "
          style={{
            backgroundImage: `url("${bgImg}")`,
          }}
        >
          <div className="transbox"></div>
        </div>
      </div>
    </div>
  );
}

EmailVerification.propTypes = {
  activateAccount: PropTypes.func.isRequired,
};

export default connect(null, { activateAccount })(EmailVerification);

