import api from '../../services/api';
import { dashboardActions, reportActions } from './types';

function flatDeep(arr, depth = 1) {
  if (depth <= 0) {
    return arr.slice();
  }

  return arr.reduce(
    (acc, val) =>
      acc.concat(Array.isArray(val) ? flatDeep(val, depth - 1) : val),
    []
  );
}

export const fetchDashData = () => dispatch => {
  return api
    .get('/dashboard')
    .then(res => res.data)
    .then(dashbord =>
      dispatch(
        {
          type: dashboardActions.FETCH_DASH_SUCCESS,
          payload: dashbord,
        },
        console.log(dashbord.asset)
      )
    );
};

export const generateReport = postData => dispatch => {
  return api
    .post('/reports', postData)
    .then(res => res.data)
    .then(report =>
      dispatch({ type: reportActions.FETCH_REPORT_SUCCESS, payload: report })
    );
};

