import api from '../../services/api';
import { activityActions } from './types';

export const fetchActivities = () => dispatch => {
  return api
    .get(`activities/`)
    .then(res => res.data)
    .then(departmentrequest =>
      dispatch({
        type: activityActions.FETCH_ACTIVIITES,
        payload: departmentrequest,
      })
    );
};
export const fetchAssetActivity = assetId => dispatch => {
  return api
    .get(`activities/assets/${assetId}`)
    .then(res => res.data)
    .then(departmentrequest =>
      dispatch({
        type: activityActions.FETCH_ASSET_ACTIVIITES,
        payload: departmentrequest,
      })
    );
};
export const fetchRequestActivity = () => dispatch => {
  return api
    .get(`activities/requests/${localStorage.request_id}`)
    .then(res => res.data)
    .then(departmentrequest =>
      dispatch({
        type: activityActions.FETCH_REQUEST_ACTIVIITES,
        payload: departmentrequest,
      })
    );
};
export const fetchProposalActivity = () => dispatch => {
  return api
    .get(`activities/proposals/${localStorage.proposal_id}`)
    .then(res => res.data)
    .then(departmentrequest =>
      dispatch({
        type: activityActions.FETCH_PROPOSAL_ACTIVIITES,
        payload: departmentrequest,
      })
    );
};
export const fetchInventoryActivity = () => dispatch => {
  return api
    .get(`activities/inventories/${localStorage.inv_id}`)
    .then(res => res.data)
    .then(departmentrequest =>
      dispatch({
        type: activityActions.FETCH_INVENTORY_ACTIVIITES,
        payload: departmentrequest,
      })
    );
};
export const fetchConsumableActivity = () => dispatch => {
  return api
    .get(`activities/consumables/${localStorage.consumable_id}`)
    .then(res => res.data)
    .then(departmentrequest =>
      dispatch({
        type: activityActions.FETCH_CONSUMABLE_ACTIVIITES,
        payload: departmentrequest,
      })
    );
};
export const fetchDepartmentActivity = () => dispatch => {
  return api
    .get(`activities/department/${localStorage.department_id}`)
    .then(res => res.data)
    .then(departmentrequest =>
      dispatch({
        type: activityActions.FETCH_DEPARTMENT_ACTIVIITES,
        payload: departmentrequest,
      })
    );
};

