import React, { Component } from 'react';
import { Chart } from 'primereact/chart';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchDashData } from '../../../../shared/redux/actions/dashboardActions';
import ReportGenerator from './reports';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
    };
  }
  componentDidMount() {
    this.props.fetchDashData();
    this.setState({ assets: this.props.dashitems['asset'] });
    // console.log(this.props.dashInventory);
  }

  render() {
    const basicData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'New Assets',
          backgroundColor: '#5C9A9A',
          data: [65, 59, 80, 81, 56, 55, 40],
        },
        {
          label: 'New Requests',
          backgroundColor: '#B8B85A',
          data: [28, 48, 40, 19, 86, 27, 90],
        },
        {
          label: 'New Proposals',
          backgroundColor: '#EAB06C',
          data: [28, 48, 40, 19, 86, 27, 90],
        },
      ],
    };

    const basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: '#495057',
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
          },
        },
        y: {
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
          },
        },
      },
    };

    const assets = this.props.dashAssets || [];
    const inventories = this.props.dashInventory || [];
    const requests = this.props.dashRequests || [];
    const proposals = this.props.dashProposals || [];

    return (
      <div>
        <div
          className="font-medium text-1xl text-700"
          style={{ margin: '10px' }}
        >
          <ul className="list-none p-0 m-0 flex align-items-center font-medium mb-3">
            <li>
              <a className="text-500 no-underline line-height-3 cursor-pointer">
                Dashboard
              </a>
            </li>
            <li className="px-2">
              <i className="pi pi-angle-right text-500 line-height-3"></i>
            </li>
            <li>
              <span className="text-700 line-height-3">Analytics</span>
            </li>
          </ul>
        </div>

        <div
          className="grid sm:m-2 m-1"
          style={{
            backgroundColor: 'whitesmoke',
            width: '80vw',

            borderRadius: '20px',
          }}
        >
          <div
            className="sm:col col"
            style={{
              backgroundColor: '#ADEBD6',
              // width: '17vw',
              margin: '5px',
              borderRadius: '20px',
            }}
          >
            <div
              style={{
                borderRadius: '20px',
              }}
              className="flex w-full align-items-start flex-column p-2 bg-white"
            >
              <div className="w-full font-medium text-2xl  text-700">
                Assets
              </div>
              <div className="w-full align-items-center text-700 flex-wrap">
                <div className="mr-5 flex align-items-center mt-3 ">
                  <i className="pi pi-users mr-2" style={{ color: 'red' }}></i>
                  <span>{this.props.dashAssetsTotal.total_count} Total</span>
                </div>
                <div className="mr-5 flex align-items-center mt-3">
                  <i className="pi pi-globe mr-2" style={{ color: 'blue' }}></i>
                  <span>
                    {this.props.dashAssetsAssigned.total_count} Assigned{' '}
                  </span>
                </div>

                <div className="flex align-items-center mt-3">
                  <i
                    className="pi pi-clock mr-2"
                    style={{ color: 'green' }}
                  ></i>
                  <span>
                    {this.props.dashAssetsDecom.total_count} Decomissioned{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="sm:col col"
            style={{
              backgroundColor: '#e2e2f5',
              // width: '17vw',
              margin: '5px',
              borderRadius: '20px',
            }}
          >
            <div
              style={{
                borderRadius: '20px',
              }}
              className="flex w-full align-items-start flex-column p-2 bg-white"
            >
              <div className="w-full font-medium text-2xl  text-700">
                Inventory
              </div>
              <div className="w-full align-items-center text-700 flex-wrap">
                <div className="mr-5 flex align-items-center mt-3 ">
                  <i className="pi pi-users mr-2" style={{ color: 'red' }}></i>
                  <span>
                    {this.props.dashInventoryTotal.total_count} Total{' '}
                  </span>
                </div>
                <div className="mr-5 flex align-items-center mt-3">
                  <i className="pi pi-globe mr-2" style={{ color: 'blue' }}></i>
                  <span>
                    {this.props.dashInventoryActive.total_count} Active{' '}
                  </span>
                </div>

                <div className="flex align-items-center mt-3">
                  <i
                    className="pi pi-clock mr-2"
                    style={{ color: 'green' }}
                  ></i>
                  <span>
                    {this.props.dashInventoryInActive.total_count} Inactive{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="sm:col col"
            style={{
              backgroundColor: '#AFE2EB',
              margin: '5px',
              borderRadius: '20px',
            }}
          >
            <div
              style={{
                borderRadius: '20px',
              }}
              className="flex w-full align-items-start flex-column p-2 bg-white"
            >
              <div className="w-full font-medium text-2xl  text-700">
                Requests
              </div>
              <div className="w-full align-items-center text-700 flex-wrap">
                <div className="mr-5 flex align-items-center mt-3 ">
                  <i className="pi pi-users mr-2" style={{ color: 'red' }}></i>
                  <span>{this.props.dashRequestsTotal.total_count} Total</span>
                </div>
                <div className="mr-5 flex align-items-center mt-3">
                  <i className="pi pi-globe mr-2" style={{ color: 'blue' }}></i>
                  <span>
                    {this.props.dashRequestsPending.total_count} Pending{' '}
                  </span>
                </div>

                <div className="flex align-items-center mt-3">
                  <i
                    className="pi pi-clock mr-2"
                    style={{ color: 'green' }}
                  ></i>
                  <span>
                    {this.props.dashRequestsApproved.total_count} Approved{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="sm:col col"
            style={{
              backgroundColor: '#FFEAD7',
              margin: '5px',
              borderRadius: '20px',
            }}
          >
            <div
              style={{
                borderRadius: '20px',
              }}
              className="flex w-full align-items-start flex-column p-2 bg-white"
            >
              <div className="w-full font-medium text-2xl  text-700">
                Proposal
              </div>
              <div className="w-full align-items-center text-700 flex-wrap">
                <div className="mr-5 flex align-items-center mt-3 ">
                  <i className="pi pi-users mr-2" style={{ color: 'red' }}></i>
                  <span>
                    {this.props.dashProposalsTotal.total_count} Total{' '}
                  </span>
                </div>
                <div className="mr-5 flex align-items-center mt-3">
                  <i className="pi pi-globe mr-2" style={{ color: 'blue' }}></i>
                  <span>
                    {this.props.dashProposalsPending.total_count} Pending{' '}
                  </span>
                </div>

                <div className="flex align-items-center mt-3">
                  <i
                    className="pi pi-clock mr-2"
                    style={{ color: 'green' }}
                  ></i>
                  <span>
                    {this.props.dashProposalsApproved.total_count} Approved{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="grid gap-3"
          style={{
            width: '80vw',
            margin: '45px 0px 15px 15px',
            borderRadius: '20px',
          }}
        >
          <div
            className="sm:col col"
            style={{ backgroundColor: 'whitesmoke', borderRadius: '20px' }}
          >
            <div className="font-medium text-2xl text-700">
              Asset Statistics
            </div>
            <Chart
              type="bar"
              data={basicData}
              options={basicOptions}
              style={{
                height: '400px',
                backgroundColor: 'white',
                margin: '10px',
              }}
            />
          </div>

          <div
            className="sm:col col"
            style={{ backgroundColor: 'whitesmoke', borderRadius: '20px' }}
          >
            <ReportGenerator />
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  fetchDashData: PropTypes.func.isRequired,
  dashitems: PropTypes.array.isRequired,
  pagesize: PropTypes.any.isRequired,
};
const mapStateToProps = state => ({
  pagesize: state.assets.pagesize,
  booksize: state.assets.booksize,
  dashitems: state.dashboard.dashitems || [],
  dashAssetsTotal: state.dashboard.dashAssetsTotal,
  dashAssetsAssigned: state.dashboard.dashAssetsAssigned,
  dashAssetsDecom: state.dashboard.dashAssetsDecom,
  dashInventoryTotal: state.dashboard.dashInventoryTotal,
  dashInventoryActive: state.dashboard.dashInventoryActive,
  dashInventoryInActive: state.dashboard.dashInventoryInActive,
  dashProposalsPending: state.dashboard.dashProposalsPending,
  dashProposalsTotal: state.dashboard.dashProposalsTotal,
  dashProposalsApproved: state.dashboard.dashProposalsApproved,
  dashRequestsTotal: state.dashboard.dashRequestsTotal,
  dashRequestsPending: state.dashboard.dashRequestsPending,
  dashRequestsApproved: state.dashboard.dashRequestsApproved,
});

export default connect(mapStateToProps, {
  fetchDashData,
})(Dashboard);
