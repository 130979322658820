import api from '../../services/api';
import Swal from 'sweetalert2';
import { requestActions } from './types';

export const fetchRequests = () => dispatch => {
  return api
    .get('/request?tag=asset')
    .then(res => res.data)
    .then(requests =>
      dispatch({
        type: requestActions.FETCH_REQUEST_SUCCESS,
        payload: requests,
      })
    );
};

export const fetchConRequests = () => dispatch => {
  return api
    .get('/request?tag=consumable')
    .then(res => res.data)
    .then(requests =>
      dispatch({
        type: requestActions.FETCH_REQUEST_SUCCESS,
        payload: requests,
      })
    );
};

export const fetchUserRequests = () => dispatch => {
  return api
    .get(`request?author_id=${localStorage.user_id}&tag=asset`)
    .then(res => res.data)
    .then(userrequests =>
      dispatch({
        type: requestActions.FETCH_USER_REQUEST,
        payload: userrequests,
      })
    );
};
export const fetchUserConRequests = () => dispatch => {
  return api
    .get(`request?author_id=${localStorage.user_id}&tag=consumable`)
    .then(res => res.data)
    .then(userrequests =>
      dispatch({
        type: requestActions.FETCH_USER_REQUEST,
        payload: userrequests,
      })
    );
};
export const fetchDepartmentRequests = () => dispatch => {
  return api
    .get(`request/?department_id=${localStorage.department_id}&tag=asset`)
    .then(res => res.data)
    .then(departmentrequest =>
      dispatch({
        type: requestActions.FETCH_DEPARTMENT_REQUEST,
        payload: departmentrequest,
      })
    );
};

export const fetchDepartmentConRequests = () => dispatch => {
  return api
    .get(`request/?department_id=${localStorage.department_id}&tag=consumable`)
    .then(res => res.data)
    .then(departmentrequest =>
      dispatch({
        type: requestActions.FETCH_DEPARTMENT_REQUEST,
        payload: departmentrequest,
      })
    );
};
export const issueRequest = issueData => dispatch => {
  return api.post('/request/assets', issueData).then(issue => {
    if (issue.status === 201) {
      dispatch(
        {
          type: requestActions.ISSUE_REQUEST_SUCCESS,
          payload: issue,
        },
        Swal.fire({
          title: 'Request sent successfully.',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        }).then(function () {
          window.location.reload();
        })
      );
    } else {
      Swal.fire({
        icon: 'error',
        text: issue.data,
        title: 'Failed',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  });
};

export const issueConRequest = issueData => dispatch => {
  return api.post('/request/consumables', issueData).then(issue => {
    if (issue.status === 201) {
      dispatch(
        {
          type: requestActions.ISSUE_REQUEST_SUCCESS,
          payload: issue,
        },
        Swal.fire({
          title: 'Request sent successfully.',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        }).then(function () {
          window.location.reload();
        })
      );
    } else {
      Swal.fire({
        icon: 'error',
        text: issue.data,
        title: 'Failed',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  });
};
export const issueHeadRequest = issueData => dispatch => {
  return api.post('/request/assets', issueData).then(issue => {
    if (issue.status === 201) {
      dispatch(
        {
          type: requestActions.ISSUE_REQUEST_SUCCESS,
          payload: issue,
        },
        localStorage.setItem('request_id', issue.data.id)
        //  console.log(issue.data)
      );
    } else {
      Swal.fire({
        icon: 'error',
        text: issue.data,
        title: 'Failed',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  });
};

export const editRequest = (updateData, id) => dispatch => {
  return api.patch(`/request/${id}`, updateData).then(update => {
    if (update.status === 200) {
      dispatch(
        {
          type: requestActions.UPDATE_REQUEST_SUCCESS,
          payload: update,
        },
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Verification Successful',
          timer: 2000,
          showConfirmButton: false,
        }).then(function () {
          window.location.reload();
        })
      );
    } else {
      Swal.fire({
        icon: 'error',
        text: update.data,
        title: 'Failed',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  });
};

export const editRequestTrans = (updateData, id) => dispatch => {
  return api.patch(`/request/${id}/transfer`, updateData).then(update => {
    if (update.status === 200) {
      dispatch(
        {
          type: requestActions.UPDATE_REQUEST_SUCCESS,
          payload: update,
        },
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Verification Successful',
          timer: 2000,
          showConfirmButton: false,
        }).then(function () {
          window.location.reload();
        })
      );
    } else {
      Swal.fire({
        icon: 'error',
        text: update.data,
        title: 'Failed',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  });
};

