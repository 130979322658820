import React from 'react';

function SubDomainError(props) {
  const bgImg = process.env.PUBLIC_URL + 'default_bg.jpg';

  return (
    // <div className="w-screen h-screen surface-200">
    //   <div class="flex   align-content-center justify-content-center flex-wrap card-container yellow-container">
    //     <div class="flex my-8 flex-column align-items-center justify-content-center w-3  bg-purple-50 font-bold text-gray-900 border-round m-2">
    //       <div class="flex my-3">
    //         <i className="pi pi-times-circle text-7xl text-orange-500"></i>
    //       </div>
    //       <div class="flex">Tenant SubDomain Error</div>
    //     </div>
    //   </div>

    // </div>

    <div className="w-screen h-screen surface-200">
      <div
        className="flex surface-section surface-200 w-full h-full "
        // style={{ marginTop: '15%', marginBottom: '15%' }}
      >
        <div className="w-12 sm:w-6 px-4 py-8 md:px-6 lg:px-8">
          <div className="border-left-2 border-pink-500">
            <span className="bg-white text-pink-500 font-bold text-2xl inline-block px-3">
              404
            </span>
          </div>
          <div className="mt-6 mb-5 font-bold text-6xl text-900">
            Sub Domain Not Found
          </div>
          <p className="text-700 text-3xl mt-0 mb-6">
            Sorry, we couldn't find this address.
          </p>
        </div>
        <div
          className="w-6 hidden sm:block"
          style={{
            // background: `url(${bgImg}) no-repeat`,
            backgroundSize: 'cover',
          }}
        ></div>
      </div>
    </div>
  );
}

export default SubDomainError;
