import React from 'react';
import LoginPage from './newPage';
class Login extends React.Component {
  render() {
    return (
      <>
        <LoginPage />
      </>
    );
  }
}

export default Login;

