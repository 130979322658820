import React, { useState } from 'react';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  useFormik,
  FormikProvider,
} from 'formik';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import {
  TenantSchema,
  jsonToFormData,
} from '../../../../shared/utils/validation';
import { createTenant } from '../../../../shared/redux/actions/tenantActions';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { FileUpload } from 'primereact/fileupload';

const fileHeader = () => {
  return (
    <div className="p-fileupload-buttonbar">
      <Button label="Select Image" icon="pi pi-plus" />
    </div>
  );
};
const buttonOptions = {
  className: 'hidden',
};

const Formikform = props => {
  const formik = useFormik({
    initialValues: {
      title: '',
      sub_domain: '',
      description: '',
      phone: '',
      email: '',
      street_address: '',
      postal_address: '',
      digital_address: '',
      logo: '',
      bg_image: '',
    },
    onSubmit: values => {
      // console.log(values);
      formik.setErrors();
      // if (formik.errors) {
      //   alert("Please fill all the required fields");
      // }
    },

    validationSchema: TenantSchema,
    validateOnChange: true,
  });

  const sub = () => {
    const payload = {
      title: formik.values.title,
      sub_domain: formik.values.sub_domain,
      description: formik.values.description,
      phone: formik.values.phone,
      email: formik.values.email,
      street_address: formik.values.street_address,
      postal_address: formik.values.postal_address,
      digital_address: formik.values.digital_address,
      logo: formik.values.logo,
      bg_image: formik.values.bg_image,
    };
    props.createTenant(jsonToFormData(payload));
  };

  return (
    <div style={{ width: '82vw', marginLeft: '10px' }}>
      <FormikProvider value={formik}>
        <form
          onSubmit={e => {
            formik.isValid ? formik.handleSubmit(e) : e.preventDefault();
          }}
          style={{ width: '82vw', marginLeft: '10px' }}
        >
          <div className="grid w-full">
            <div class="field col-6">
              <label htmlFor="title" className="block font-normal mb-2">
                Tenant Name
              </label>
              <Field name="title">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <div>
                    <InputText
                      type="text"
                      className="w-full mb-1"
                      placeholder="Tenant Name"
                      value={formik.values.title}
                      tooltip="Name of Tenant"
                      tooltipOptions={{ position: 'bottom' }}
                      onChange={event => formik.handleChange(event, 'title')}
                      {...field}
                      autoFocus
                    />
                    <small id="username1-help" className="block">
                      eg: AITI-KACE
                    </small>

                    {meta.touched && meta.error && (
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="error-message"
                      />
                    )}
                  </div>
                )}
              </Field>
            </div>

            <div class="field col-6">
              <label htmlFor="sub_domain" className="block font-normal mb-2">
                Sub-domain
              </label>
              <Field name="sub_domain">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <div>
                    <InputText
                      id="sub_domain"
                      type="text"
                      className="w-full mb-1"
                      placeholder=" Sub-domain"
                      value={formik.values.sub_domain}
                      tooltip="Sub Domain of Tenant"
                      tooltipOptions={{ position: 'bottom' }}
                      onChange={event =>
                        formik.handleChange(event, 'sub_domain')
                      }
                      {...field}
                    />
                    <small id="username1-help" className="block">
                      eg: aiti-kace
                    </small>
                    {meta.touched && meta.error && (
                      <ErrorMessage
                        name="sub_domain"
                        component="div"
                        className="error-message"
                      />
                    )}
                  </div>
                )}
              </Field>
            </div>

            <div class="field col-6">
              <label htmlFor="email" className="block font-normal mb-2">
                Email Address
              </label>
              <Field name="email">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <div>
                    <InputText
                      id="email"
                      type="text"
                      className="w-full mb-1"
                      placeholder="Email Address"
                      value={formik.values.email}
                      tooltip="Email Address of Tenant"
                      tooltipOptions={{ position: 'bottom' }}
                      onChange={event => formik.handleChange(event, 'email')}
                      {...field}
                    />
                    <small id="username1-help" className="block">
                      eg: admin@aiti.com
                    </small>
                    {meta.touched && meta.error && (
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    )}
                  </div>
                )}
              </Field>
            </div>

            <div class="field col-6">
              <label htmlFor="phone" className="block font-normal mb-2">
                Phone
              </label>
              <Field name="phone">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <div>
                    <InputText
                      id="phone"
                      type="text"
                      className="w-full mb-1"
                      placeholder="Phone Number"
                      value={formik.values.phone}
                      tooltip="Contact of Tenant"
                      tooltipOptions={{ position: 'bottom' }}
                      onChange={event => formik.handleChange(event, 'phone')}
                      {...field}
                    />
                    <small id="username1-help" className="block">
                      eg: 0241234576
                    </small>
                    {meta.touched && meta.error && (
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error-message"
                      />
                    )}
                  </div>
                )}
              </Field>
            </div>

            <div class="field col-6">
              <label
                htmlFor="street_address"
                className="block font-normal mb-2"
              >
                Street Address
              </label>
              <Field name="street_address">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <div>
                    <InputText
                      id="street_address"
                      type="text"
                      className="w-full mb-1"
                      placeholder="Street Address"
                      value={formik.values.street_address}
                      tooltip="Street Address of Tenant"
                      tooltipOptions={{ position: 'bottom' }}
                      onChange={event =>
                        formik.handleChange(event, 'street_address')
                      }
                      {...field}
                    />
                    <small id="username1-help" className="block">
                      eg: Ridge Gardens
                    </small>
                    {meta.touched && meta.error && (
                      <ErrorMessage
                        name="street_address"
                        component="div"
                        className="error-message"
                      />
                    )}
                  </div>
                )}
              </Field>
            </div>
            <div class="field col-6">
              <label
                htmlFor="postal_address"
                className="block font-normal mb-2"
              >
                Postal Address
              </label>
              <Field name="postal_address">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <div>
                    <InputText
                      id="postal_address"
                      type="text"
                      className="w-full mb-1"
                      placeholder="Postal Address"
                      value={formik.values.postal_address}
                      tooltip="Postal Address of Tenant"
                      tooltipOptions={{ position: 'bottom' }}
                      onChange={event =>
                        formik.handleChange(event, 'postal_address')
                      }
                      {...field}
                    />
                    <small id="username1-help" className="block">
                      eg: 204 Ministries
                    </small>

                    {meta.touched && meta.error && (
                      <ErrorMessage
                        name="postal_address"
                        component="div"
                        className="error-message"
                      />
                    )}
                  </div>
                )}
              </Field>
            </div>
            <div class="field col-6">
              <label htmlFor="basic" className="mb-2 font-normal">
                Digital Address
              </label>
              <Field name="digital_address">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <div>
                    <InputMask
                      id="digital_address"
                      name="digital_address"
                      mask="aa-999-9999"
                      placeholder="AA-001-1010"
                      className="w-full mb-1"
                      value={formik.values.digital_address}
                      tooltip="Digital Address of Tenant"
                      tooltipOptions={{ position: 'bottom' }}
                      onChange={event =>
                        formik.handleChange(event, 'digital_address')
                      }
                      {...field}
                    />
                    <small id="username1-help" className="block">
                      eg: GA-032-2321
                    </small>

                    {meta.touched && meta.error && (
                      <ErrorMessage
                        name="digital_address"
                        component="div"
                        className="error-message"
                      />
                    )}
                  </div>
                )}
              </Field>
            </div>
            <div class="field col-6">
              <label htmlFor="description" className="block font-normal mb-2">
                Tenant Description
              </label>
              <Field name="description">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <div>
                    <InputTextarea
                      id="description"
                      type="text"
                      className="w-full mb-1"
                      placeholder="Tenant Description"
                      value={formik.values.description}
                      tooltip="Description of Tenant"
                      tooltipOptions={{ position: 'bottom' }}
                      onChange={event =>
                        formik.handleChange(event, 'description')
                      }
                      {...field}
                    />
                    <small id="username1-help" className="block">
                      eg: Instance for AITI-KACE
                    </small>
                    {meta.touched && meta.error && (
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="error-message"
                      />
                    )}
                  </div>
                )}
              </Field>
            </div>
            <div class="field col-6">
              <label htmlFor="logo" className="mb-2 font-normal">
                Logo
              </label>
              <Field name="logo">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <div>
                    <FileUpload
                      type="file"
                      name="logo"
                      id="logo"
                      uploadOptions={buttonOptions}
                      cancelOptions={buttonOptions}
                      chooseLabel="Select image"
                      tooltip="Tenant Logo"
                      tooltipOptions={{ position: 'bottom' }}
                      onSelect={event => {
                        formik.setFieldValue('logo', event.files[0]);
                      }}
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <ErrorMessage
                        name="logo"
                        component="div"
                        className="error-message"
                      />
                    )}
                  </div>
                )}
              </Field>
            </div>
            <div class="field col-6">
              <label htmlFor="bg_image" className="mb-2 font-normal">
                Background Image
              </label>
              <FileUpload
                type="file"
                name="bg_image"
                id="bg_image"
                chooseLabel="Select image"
                tooltip="Image for Login Screen"
                tooltipOptions={{ position: 'bottom' }}
                uploadOptions={buttonOptions}
                cancelOptions={buttonOptions}
                onSelect={event => {
                  formik.setFieldValue('bg_image', event.files[0]);
                }}
              ></FileUpload>
            </div>
          </div>
          <div className="flex -mt-1 mb-8 justify-content-center align-content-center w-full">
            <Button
              type="submit"
              onClick={sub}
              icon="pi pi-plus"
              label="Add New Tenant"
              className="w-4 mb-5"
            ></Button>
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};

export default connect(null, {
  createTenant,
})(Formikform);

