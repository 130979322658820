import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';

import { LoginSchema } from '../../../../shared/utils/validation';
import {
  loginUser,
  loginAdmin,
  requestPasswordReset,
  verifyEmail,
} from '../../../../shared/redux/actions/authActions';
import { Dialog } from 'primereact/dialog';

import { initiateSocket } from '../../../../shared/services/socket';
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmResDialog: false,
      useremail: '',
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.SendConfirmation = this.SendConfirmation.bind(this);
  }
  handleOpen() {
    this.setState({ confirmResDialog: true });
  }
  handleClose() {
    this.setState({ confirmResDialog: false });
  }
  handleInputChange(evt, field) {
    this.setState({ [field]: evt.target.value });
  }
  async SendConfirmation() {
    const email = this.state.useremail;
    await this.props.requestPasswordReset('users', email);
    this.setState({ confirmResDialog: false });
  }
  getEmail() {
    console.log(typeof this.state?.email);
    return this.state?.useremail;
  }
  ResetDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Reset Password"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.SendConfirmation();
          this.handleClose();
        }}
      />
    </React.Fragment>
  );

  render() {
    const bgImg = process.env.PUBLIC_URL + 'default_bg.jpg';

    return (
      <div className="bg-grad w-full h-screen flex">
        <div className=" flex shadow-6 login-box ">
          <div className="sm:w-6 bd-rad bg-white p-2 w-12">
            <div className="sm:w-10 w-12 align-self-center flex align-items-center my-auto mx-auto">
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={LoginSchema}
                onSubmit={async (
                  { email, password },

                  { setStatus, setSubmitting }
                ) => {
                  if (window.location.href.includes('admin')) {
                    await this.props.loginAdmin(email, password, 'admin');
                    return (
                      window.location.assign('admin/dashboard'),
                      // new WebSocket(
                      //   `ws://dev-1.aiti-kace.com.gh:3110/ws/${localStorage.push_id}`
                      // ),
                      // initiateSocket(),
                      setStatus(),
                      error => {
                        setSubmitting(false);
                        setStatus(error);
                        console.log(error);
                      }
                    );
                  } else {
                    await this.props.loginUser(email, password);
                    if (this.props.user.role.title === 'Staff') {
                      return (
                        window.location.assign('/request'),
                        // new WebSocket(
                        //   `ws://dev-1.aiti-kace.com.gh:3110/ws/${localStorage.push_id}`
                        // ),
                        // initiateSocket(),
                        setStatus(),
                        error => {
                          setSubmitting(false);
                          setStatus(error);
                          console.log(error);
                        }
                      );
                    } else {
                      return (
                        window.location.assign('/dashboard'),
                        // new WebSocket(
                        //   `ws://dev-1.aiti-kace.com.gh:3110/ws/${localStorage.push_id}`
                        // ),
                        // initiateSocket(),
                        setStatus(),
                        error => {
                          setSubmitting(false);
                          setStatus(error);
                          console.log(error);
                        }
                      );
                    }
                  }
                }}
              >
                {(errors, status, touched, isSubmitting) => (
                  <div className="flex w-full h-full align-items-center justify-content-center">
                    <div className="align-items-center justify-content-center md:px-3 md:py-3   border-round w-9 mt-3 ">
                      <div className="text-5xl mb-2">
                        <span>Welcome back</span>
                      </div>
                      <div className="text-md text-500 mb-3 lg:mb-6 ">
                        <span>Please enter your details</span>
                      </div>
                      <div className="text-center text-600 text-md font-medium mb-1">
                        E-ASSET MANAGEMENT SYSTEM
                      </div>
                      <Form>
                        <div className="email mt-2">
                          <label
                            htmlFor="email"
                            className="block text-700 font-medium mb-2"
                          >
                            Email
                          </label>
                          <Field name="email">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <div>
                                <InputText
                                  type="text"
                                  name="email"
                                  className="w-full mb-1"
                                  placeholder="Email"
                                  {...field}
                                />

                                {meta.touched && meta.error && (
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="error-message"
                                  />
                                )}
                              </div>
                            )}
                          </Field>
                        </div>

                        <div className="password">
                          <label
                            htmlFor="password"
                            className="block text-700 font-medium mb-2"
                          >
                            Password
                          </label>
                          <Field name="password">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <div>
                                <InputText
                                  type="password"
                                  className="w-full mb-1"
                                  placeholder="Password"
                                  name="password"
                                  {...field}
                                />

                                {meta.touched && meta.error && (
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="error-message"
                                  />
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="flex w-full justify-content-between mb-2">
                          <div className="flex">
                            <Checkbox
                              id="rememberme"
                              // onChange={(e) => setChecked(e.checked)}
                              // checked={checked}
                              binary
                              className="mr-2"
                            />
                            <label htmlFor="rememberme" className="flex">
                              Remember me
                            </label>
                          </div>
                          <div className="flex">
                            <a
                              onClick={() => this.handleOpen()}
                              className="font-medium no-underline text-blue-500 text-right cursor-pointer"
                            >
                              Forgot your password?
                            </a>
                          </div>
                        </div>
                        <div className="col-12">
                          <Button
                            label="Sign In"
                            icon="pi pi-user"
                            type="submit"
                            className="w-full"
                            disabled={isSubmitting}
                            style={{ background: '#1e3a8a' }}
                            loading={isSubmitting}
                          />
                        </div>
                      </Form>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
            <div className="text-xs align-content-center justify-content-center font-italic flex -mt-2">
              <p className="flex">Powered by:</p>
              <div className="flex ">
                <img
                  src={process.env.PUBLIC_URL + 'aiti-logo.png'}
                  alt="logo"
                  // height={50}
                  className=" w-6rem h-2rem "
                />
              </div>
            </div>
          </div>
          <div
            className=" sm:w-6 w-0 p-0 h-full bd-rad  bg-no-repeat bg-cover flex align-items-end "
            style={{
              backgroundImage: `url("${bgImg}")`,
            }}
          >
            <div className="transbox"></div>
          </div>
        </div>
        <Dialog
          draggable={false}
          visible={this.state['confirmResDialog']}
          style={{ width: '35vw' }}
          header="Request Password Reset"
          modal
          className="p-fluid"
          footer={this.ResetDialogFooter}
          onHide={this.handleClose}
        >
          <div className="confirmation-content">
            <i className="pi pi-envelope mr-2" style={{ fontSize: '1.5rem' }} />

            <span>Enter Email Address</span>
            <InputText
              id="useremail"
              name="useremail"
              className="mt-2"
              required={true}
              onChange={event => {
                this.handleInputChange(event, 'useremail');
              }}
              value={this.state.useremail}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

LoginPage.propTypes = {
  loginUser: PropTypes.func.isRequired,
  requestPasswordReset: PropTypes.func.isRequired,
  verifyEmail: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  user: state.auth.user,
  verifieddata: state.auth.verifieddata,
  testingdata: state.auth.testingdata,
});
export default connect(mapStateToProps, {
  loginUser,
  loginAdmin,
  requestPasswordReset,
  verifyEmail,
})(LoginPage);
