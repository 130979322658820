import { activityActions } from '../actions/types';

const initialState = {
  activities: [], ///all fetched assets
  assetActivities: [],
  proposalActivities: [],
  requestActivities: [],
  inventoryActivities: [],
  consumableActivities: [],
  departmentActivities: [],
};
const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case activityActions.FETCH_ACTIVIITES:
      return {
        ...state,
        activities: action.payload.data,
      };
    case activityActions.FETCH_ASSET_ACTIVIITES:
      return {
        ...state,
        assetActivities: action.payload.data,
      };
    case activityActions.FETCH_CONSUMABLE_ACTIVIITES:
      return {
        ...state,
        consumableActivities: action.payload.data,
      };
    case activityActions.FETCH_DEPARTMENT_ACTIVIITES:
      return {
        ...state,
        departmentActivities: action.payload.data,
      };
    case activityActions.FETCH_INVENTORY_ACTIVIITES:
      return {
        ...state,
        inventoryActivities: action.payload.data,
      };
    case activityActions.FETCH_PROPOSAL_ACTIVIITES:
      return {
        ...state,
        proposalActivities: action.payload.data,
      };
    case activityActions.FETCH_REQUEST_ACTIVIITES:
      return {
        ...state,
        requestActivities: action.payload.data,
      };
    default:
      return state;
  }
};

export default activityReducer;
