import Swal from 'sweetalert2';
import api from '../../services/api';
import { userService } from '../../services/auth.service';
// import history from "../../services/history";
import { authActions } from './types';

export const loginUser = (email, password) => dispatch => {
  return (
    dispatch(request({ email, password })),
    api
      .post('/login?account=users', { email, password })
      .then(login => {
        dispatch(success(login));
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response.status === 417) {
          Swal.fire({
            title: 'Oops...',
            text: 'User Account Not Verified',
            icon: 'warning',
            confirmButtonText: 'Send Verification Email',
          }).then(res => {
            if (res.isConfirmed) {
              dispatch(sendActivateAccountLink('users', email));
            }
          });
        }
      })
  );
};

export const loginAdmin = (email, password) => dispatch => {
  return (
    dispatch(request({ email, password })),
    api
      .post('/login?account=administrators', { email, password })
      .then(login => {
        dispatch(adminSuccess(login));
      })
      .catch(error => {
        dispatch(failure(error));
      })
  );
};

function request(user) {
  return { type: authActions.LOGIN_REQUEST, payload: user };
}
function success(user) {
  return { type: authActions.LOGIN_SUCCESS, payload: user.data };
}
function adminSuccess(user) {
  return { type: authActions.LOGIN_ADMIN_SUCCESS, payload: user.data };
}
function failure(error) {
  return { type: authActions.LOGIN_ERROR, payload: error };
}

export function logout() {
  userService.logout();
  window.location.assign('/');
  return { type: authActions.LOGOUT };
}

export const verifyEmail = email => dispatch => {
  return api
    .get(`/users/?search=email&value=${email}`)
    .then(res => res.data)
    .then(userdetail =>
      dispatch(
        {
          type: authActions.VERIFY_EMAIL,
          payload: userdetail.data[0],
        },
        console.log(userdetail.data[0]),
        localStorage.setItem(
          'verified_id',
          JSON.stringify(userdetail.data[0].id)
        )
      )
    );
};

export const sendVerifyEmailCode = (userType, email) => dispatch => {
  return api
    .post(`/send-email-verification-code?account=${userType}`, { email })
    .then(reset => {
      if (reset.status === 200) {
        dispatch(
          {
            type: authActions.VERIFY_EMAIL,
            payload: reset.data,
          },
          Swal.fire({
            icon: 'success',
            title: 'Request Successful',
            text: 'Check email for confirmation Link',
            showConfirmButton: true,
            timer: 5000,
          }).then(function () {
            window.location.replace('/verify-email');
          })
        );
      } else {
        Swal.fire({
          icon: 'error',
          text: reset.data,
          title: 'Request failed',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    });
};

export const sendActivateAccountLink = (userType, email) => dispatch => {
  return api
    .post(`/get-activation-link?account=${userType}&email=${email}`)
    .then(reset => {
      if (reset.status === 200) {
        dispatch(
          {
            type: authActions.VERIFY_EMAIL,
            payload: reset.data,
          },
          Swal.fire({
            icon: 'success',
            title: 'Request Successful',
            text: 'Check email for Activation Link',
            showConfirmButton: true,
            timer: 5000,
          }).then(function () {
            window.location.reload();
          })
        );
      } else {
        Swal.fire({
          icon: 'error',
          text: reset.data,
          title: 'Request failed',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    });
};

export const requestPasswordReset = (userType, email) => dispatch => {
  return api
    .post(`/send-forgot-password-link?account=${userType}`, { email })
    .then(reset => {
      if (reset.status === 200) {
        dispatch(
          {
            type: authActions.REQUEST_RESET,
            payload: reset.data,
          },
          Swal.fire({
            icon: 'success',
            title: 'Request Successful',
            text: 'Check email for Reset Link',
            showConfirmButton: true,
            timer: 5000,
          }).then(function () {
            window.location.replace('/reset-password');
          })
        );
      } else {
        Swal.fire({
          icon: 'error',
          text: reset.data,
          title: 'Request failed',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    });
};

export const passwordReset = (token, formData) => dispatch => {
  return api
    .patch(`/accounts/update-password?token=${token}`, formData)
    .then(post => {
      if (post.status === 200) {
        dispatch(
          {
            type: authActions.PASSWORD_RESET,
            payload: post,
          },
          Swal.fire({
            title: 'Password reset successfull',
            text: 'Proceed to signin',
            icon: 'success',
            timer: 2000,
            showConfirmButton: true,
          }).then(function () {
            window.location.replace('/signin');
          })
        );
      } else {
        Swal.fire({
          icon: 'error',
          text: post.data,
          title: 'Failed',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    });
};

export const activateAccount = token => dispatch => {
  return api.patch(`/accounts/activate-account?token=${token}`).then(post => {
    if (post.status === 200) {
      dispatch(
        {
          type: authActions.ACTIVATE_ACCOUNT,
          payload: post,
        },
        Swal.fire({
          title: 'Account activated successfull',
          text: 'Proceed to signin',
          icon: 'success',
          timer: 2000,
          showConfirmButton: true,
        }).then(function () {
          window.location.replace('/signin');
        })
      );
    } else {
      Swal.fire({
        icon: 'error',
        text: post.data,
        title: 'Failed',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  });
};
export const handleChangePassword = formData => {
  const { currentPassword, newPassword, newPasswordRepeated } = formData;

  this.props.dispatch({
    type: authActions.CHANGE_PASSWORD__REQUESTED,
    payload: {
      userId: this.props.auth.id,
      currentPassword,
      newPassword,
      newPasswordRepeated,
    },
  });
};

