import { fileActions } from '../actions/types';

const initialState = {
  fileList: [], ///all fetched assets
  assetFiles: [],
  branchFiles: [],
  file: {}, ///post asset
  pagesize: '',
  booksize: '',
};

const filesReducer = (state = initialState, action) => {
  switch (action.type) {
    case fileActions.FETCH_ALL_FILES:
      return {
        ...state,
        fileList: action.payload.data,
        pagesize: action.payload.pg_size,
        booksize: action.payload.bk_size,
        loading: false,
        error: false,
      };
    case fileActions.FETCH_ASSET_FILES:
      return {
        ...state,
        assetFiles: action.payload.data,
        pagesize: action.payload.pg_size,
        booksize: action.payload.bk_size,
        loading: false,
        error: false,
      };
    case fileActions.FETCH_BRANCH_FILES:
      return {
        ...state,
        branchFiles: action.payload.data,
        pagesize: action.payload.pg_size,
        booksize: action.payload.bk_size,
        loading: false,
        error: false,
      };
    case fileActions.UPDATE_FILE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case fileActions.UPDATE_FILE_SUCCESS:
      return {
        ...state,
        fileList: state.fileList.map((content, i) =>
          content.id === action.payload.id
            ? {
                ...content,
                title: action.payload.title,
                quantity: action.payload.quantity,
                inventory_id: action.payload.inventory_id,
                description: action.payload.description,
                unit_price: action.payload.unit_price,
              }
            : content
        ),
      };
    case fileActions.ADD_FILE:
      return {
        ...state,
        file: action.payload,
      };
    case fileActions.DELETE_FILE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case fileActions.DELETE_FILE_SUCCESS:
      return {
        ...state,
        fileList: state.fileList.filter(file => file.id !== action.payload),
      };
    default:
      return state;
  }
};

export default filesReducer;

