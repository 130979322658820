import './App.css';
import RouterFunction from './shared/routes';
import React, { useEffect } from 'react';
import { getConfig } from './shared/services/config.service';
import { connect, useDispatch } from 'react-redux';
import SubDomainError from './tenant-app/pages/main/subdomainError';
import AdminRoutes from './shared/routes/admin';

function App(props) {
  const dispatch = useDispatch();
  const tenantInfo = props.tenantInfo;
  const host = window.location.host.split('.')[0];
  const protocol = window.location.protocol;

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);

  // use https redirect when https protocol
  const upgradeMetaNotIncluded =
    document.head.querySelector('[name="HTTPS Upgrade"]') == null;
  if (protocol === 'https:' && upgradeMetaNotIncluded) {
    const meta = document.createElement('meta');
    meta.httpEquiv = 'Content-Security-Policy';
    meta.name = 'HTTPS Upgrade';
    meta.content = 'upgrade-insecure-requests';
    document.head.appendChild(meta);
  }

  if (host === 'admin') {
    return (
      <div className=" overflow-x-hidden">
        <AdminRoutes />
      </div>
    );
  }
  if (host !== 'admin' && (tenantInfo === undefined || tenantInfo === null)) {
    return <SubDomainError />;
  }
  // if (host !== 'admin' && Object.keys(tenantInfo).length === 0) {
  //   return <SubDomainError />;
  // }
  // if (host !== 'admin' && typeof tenantInfo === 'undefined') {
  //   return <SubDomainError />;
  // }
  else {
    return (
      <div className=" overflow-x-hidden">
        <RouterFunction />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tenantInfo: state.tenants.tenantConfig,
});
export default connect(mapStateToProps, { getConfig })(App);

