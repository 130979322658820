import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { connect } from 'react-redux';
import {
  createFiles,
  fetchAssetFiles,
  deleteFiles,
} from '../../../../shared/redux/actions/fileActions';
import { jsonToFormData } from '../../../../shared/utils/validation';
import Can from '../../../../shared/casl/can';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';

import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
class AssetDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filesToGo: [],
      showUpload: false,
      showButton: true,
    };

    this.onUpload = this.onUpload.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.confirm = this.confirm.bind(this);
    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);
  }
  componentDidMount() {
    this.props.fetchAssetFiles();
    // console.log(this.props.assetFiles);
  }
  handleShow() {
    this.setState({
      showUpload: !this.state.showUpload,
      showButton: !this.state.showButton,
    });
  }

  handleSelect(event) {
    var files = [];
    files.push(...event.files);
    const formData = new FormData();
    formData.append('files', ...files);
    this.props.createFiles('asset', formData, localStorage.assetid);
  }

  onUpload(event) {
    var files = [];
    files.push(...event.files);
    const formData = new FormData();
    formData.append('files', ...files);
    // console.log(formData.get('files'));
    this.props.createFiles('asset', formData, localStorage.assetid);
  }
  accept(file) {
    this.props.deleteFiles(file.id);
  }

  reject(event) {
    this.toast.show({
      severity: 'warn',
      summary: 'Cancelled',
      detail: 'Action Cancelled',
      life: 3000,
    });
  }

  confirm(event, file) {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.accept(file),
      reject: () => this.reject(event),
    });
  }

  renderIcon(file) {
    switch (file.upload_type) {
      case 'IMAGE':
        return (
          <Image
            src={file.url}
            alt=""
            imageClassName="
            w-full h-full border-round object-fit-contain"
            preview
          ></Image>
        );
      case 'DOCUMENT':
        return (
          <img
            src={process.env.PUBLIC_URL + '/images/doc.svg'}
            className="w-full h-full border-round object-fit-contain"
            alt=""
          />
        );
      case 'PDF':
        return (
          <img
            src={process.env.PUBLIC_URL + '/images/pdf.svg'}
            className="w-full h-full border-round object-fit-contain"
            alt=""
          />
        );
      case 'EXCEL':
        return (
          <img
            src={process.env.PUBLIC_URL + '/images/excel.svg'}
            className="w-full h-full border-round object-fit-contain"
            alt=""
          />
        );
      case 'AUDIO':
        return (
          <img
            src={process.env.PUBLIC_URL + '/images/audio.svg'}
            className="w-full h-full border-round object-fit-contain"
            alt=""
          />
        );
      case 'VIDEO':
        return (
          <img
            src={process.env.PUBLIC_URL + '/images/video.svg'}
            className="w-full h-full border-round object-fit-contain"
            alt=""
          />
        );
      default:
        return (
          <img
            src={process.env.PUBLIC_URL + 'doc.svg'}
            className="w-full h-full border-round object-fit-contain"
            alt=""
          />
        );
    }
  }

  render() {
    const { showUpload } = this.state;
    const filesLength = this.props.assetFiles.length;
    const lkj =
      'http://dev-1.aiti-kace.com.gh:3110/uploads/media/images/uploads/2022/08/05/3d.jpeg';

    return (
      <div className="w-full">
        <ConfirmPopup />
        <Toast ref={el => (this.toast = el)} />

        <div className="flex justify-content-end">
          {this.state.showButton ? (
            <Button
              label="Add File"
              icon="pi pi-plus"
              onClick={() => this.handleShow()}
              className="p-button-primary"
            />
          ) : (
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={() => this.handleShow()}
              className="p-button-primary"
            />
          )}
        </div>
        {showUpload && (
          <div className="mt-3 p-3">
            {/* <span class="text-lg font-medium text-900 mb-3">
              Select File to Upload
            </span> */}

            <FileUpload
              name="filesToGo"
              id="filesToGo"
              customUpload={true}
              uploadHandler={event => this.onUpload(event)}
              multiple
              // onSelect={event => this.handleSelect(event)}
              type="file"
              maxFileSize={1000000}
              emptyTemplate={
                <p className="m-0 mt-3">
                  Drag and drop files to here to upload.
                </p>
              }
            />
          </div>
        )}

        <div class="col-12 p-3">
          <div>
            {/* <div class="flex align-items-center justify-content-between mb-4">
              <span class="text-xl font-medium text-900">Files</span>
              <button
                pbutton=""
                pripple=""
                icon="pi pi-refresh"
                class="p-element p-ripple p-button-rounded p-button-outlined p-button p-component p-button-icon-only"
              >
                <span
                  class="p-button-icon pi pi-refresh"
                  aria-hidden="true"
                ></span>
                <span aria-hidden="true" class="p-button-label">
                  &nbsp;
                </span>
                <span class="p-ink"></span>
              </button>
            </div> */}
            <div class="grid">
              {filesLength > 0 ? (
                this.props.assetFiles.map(file => (
                  <div class="col-12 sm:col-6 md:col-6 lg:col-3 xl:col-2 p-3 h-12rem cursor-pointer relative">
                    <div class="text-center absolute w-10">
                      <div
                        class="border-1 border-200 surface-50  flex align-items-center justify-content-center border-round mb-3 mx-auto"
                        style={{ width: '100%', height: '7rem' }}
                      >
                        {this.renderIcon(file)}
                      </div>
                      <span class="text-900 font-medium mt-8">
                        {file.filename}
                      </span>
                      <a
                        class="text-600 text-sm mt-1 no-underline"
                        href={lkj}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <div class="text-600 text-sm mt-2">520KB</div>
                      </a>
                    </div>
                    {this.props.disabled && (
                      <Button
                        tooltip="Remove this image"
                        tooltipPosition="bottom"
                        className="p-button-rounded p-button-danger absolute top-0 right-0 h-3rem w-3rem"
                        icon="pi pi-trash"
                        onClick={event => this.confirm(event, file)}
                      ></Button>
                    )}
                  </div>
                ))
              ) : (
                <div class="surface-card shadow-2 border-round p-4 w-screen">
                  <h3 class="text-500  font-medium">No files for this item</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AssetDocuments.propTypes = {
  createFiles: PropTypes.func.isRequired,
  deleteFiles: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  assetFiles: state.fileUploads.assetFiles,
});

export default connect(mapStateToProps, {
  createFiles,
  fetchAssetFiles,
  deleteFiles,
})(AssetDocuments);

