export const sideMenu = [
  {
    label: 'Dashboard',
    action: 'view',

    subject: 'Dashboard',
    Icon: <i className="pi pi-home text-xl mr-3 text-white" />,
    to: '/dashboard',
  },
  {
    label: 'Assets',
    action: 'view',
    subject: 'Assets',
    to: '/asset',
    Icon: <i className="pi pi-shopping-cart text-xl mr-3 text-white" />,
    children: [
      {
        name: 'AssetForm',
        label: 'New ',
        to: '/assetform',
        action: 'add',
        subject: 'Assets',
        Icon: <i className="pi pi-plus text-xl mr-3 text-white" />,
      },
      {
        name: 'Asset',
        label: 'Asset List',
        to: '/asset',
        action: 'view',
        subject: 'Assets',
        Icon: <i className="pi pi-list text-xl mr-3 text-white" />,
      },
    ],
  },

  {
    label: 'Consumables',
    to: '/consumables',
    Icon: <i className="pi pi-palette text-xl mr-3 text-white" />,
    action: 'view',
    subject: 'Consumables',
  },
  {
    label: 'Inventories',
    to: '/inventory',
    Icon: <i className="pi pi-table text-xl mr-3 text-white" />,
    action: 'view',
    subject: 'Inventories',
  },

  {
    label: 'Requests',
    to: '/requestlist',
    Icon: <i className="pi pi-ticket text-xl mr-3 text-white" />,
    action: 'verify',
    subject: 'Requests',
  },

  {
    label: 'Request',
    to: '/request',
    Icon: <i className="pi pi-tag text-xl mr-3 text-white" />,
    action: 'add',
    subject: 'Requests',
  },
  {
    label: 'Recommendations',
    to: '/recommendation',
    Icon: <i className="pi pi-lock-open text-xl mr-3 text-white" />,
    action: 'verify',
    subject: 'Proposals',
  },

  {
    label: 'Recommendation',
    to: '/requestrecommendation',
    Icon: <i className="pi pi-unlock text-xl mr-3 text-white" />,
    action: 'add',
    subject: 'Proposals',
  },

  {
    label: 'Categories',
    to: '/category',
    Icon: <i className="pi pi-star text-xl mr-3 text-white" />,
    action: 'view',
    subject: 'Categories',
  },

  {
    label: 'Departments',
    to: '/base-department',
    Icon: <i className="pi pi-building text-xl mr-3 text-white" />,
    action: 'view',
    subject: 'Departments',
    children: [
      {
        name: 'Departments',
        label: 'Departments List',
        to: '/base-department',
        action: 'view',
        subject: 'Departments',
        Icon: <i className="pi pi-list text-xl mr-3 text-white" />,
      },
      {
        name: 'Department',
        label: 'Assigned Departments List',
        to: '/branch-department',
        action: 'view',
        subject: 'Departments',
        Icon: <i className="pi pi-list text-xl mr-3 text-white" />,
      },
    ],
  },

  {
    label: 'Suppliers',
    to: '/vendor',
    Icon: <i className="fa fa-truck text-xl mr-3 text-white" />,
    action: 'view',
    subject: 'Vendors',
  },

  {
    label: 'Manufacturers',
    to: '/manufacturers',
    Icon: <i className="pi pi-cog text-xl mr-3 text-white" />,
    action: 'view',
    subject: 'Manufacturers',
  },
  {
    label: 'Packages',
    to: '/subscription-packages',
    Icon: <i className="pi pi-paperclip text-xl mr-3 text-white" />,
    action: 'view',
    subject: 'Packages',
  },
  {
    label: 'Branches',
    to: '/location',
    Icon: <i className="pi pi-map-marker text-xl mr-3 text-white" />,
    action: 'view',
    subject: 'Branches',
  },

  {
    label: 'Tenant',
    to: '/tenant',
    Icon: <i className="pi pi-building text-xl mr-3 text-white" />,
    action: 'view',
    subject: 'System_configuration',
    children: [
      {
        name: 'Tenant',
        label: 'New',
        to: '/tenant',
        Icon: <i className="pi pi-plus text-xl mr-3 text-white" />,
        action: 'manage',
        subject: 'System_configuration',
      },
      {
        name: 'Tenant',
        label: 'Tenant List',
        to: '/tenant',
        action: 'view',
        subject: 'System_configuration',
        Icon: <i className="pi pi-plus text-xl mr-3 text-white" />,
      },
    ],
  },

  {
    label: 'Tenant Config',
    to: '/tenant-config',
    Icon: <i className="pi pi-cog text-xl mr-3 text-white" />,
    action: 'view',
    subject: 'Tenant_configuration',
    children: [
      {
        name: 'Tenant Config',
        label: 'Edit Configuration',
        to: '/tenant-config',
        Icon: <i className="pi pi-pencil text-xl mr-3 text-white" />,
        action: 'manage',
        subject: 'Tenant_configuration',
      },
      {
        name: 'Tenant Config',
        label: 'Tenant Aggregations',
        to: '/aggregation',
        Icon: <i className="fa fa-anchor text-xl mr-3 text-white" />,
        action: 'manage',
        subject: 'Tenant_configuration',
      },
    ],
  },

  {
    label: 'Users',
    to: '/user',
    Icon: <i className="pi pi-users text-xl mr-3 text-white" />,
    action: 'view',
    subject: 'Users',
  },

  // {
  //   label: "Forwarded Proposals",
  //   to: "/fore-proposals",
  //   Icon: <i className="pi pi-tag text-xl mr-3 text-white" />,

  //   // children: [
  //   //   {
  //   //     label: "Proposal List",
  //   //     to: "/fore-proposals",
  //   //     Icon: <i className="pi pi-list text-xl mr-3 text-white" />,
  //   //   },
  //   // ],
  // },
];

