export const assetActionTypes = Object.freeze({
  FETCH_ASSETS: 'FETCH_ASSETS',
  FETCH_ASSETS_SUCCESS: 'FETCH_ASSETS_SUCCESS',
  FETCH_ASSETS_ERROR: 'FETCH_ASSETS_ERROR',
  FETCHAVAILABLE_ASSETS: 'FETCHAVAILABLE_ASSETS',
  FETCHNON_INVASSETS: 'FETCHNON_INVASSETS',
  FETCH_ASSET_BY_ID: 'FETCH_ASSET_BY_ID',
  FETCHDECOMMISSION_ASSETS: 'FETCHDECOMMISSION_ASSETS',
  ADD_ASSET: 'ADD_ASSET',
  ADD_ASSET_SUCCESS: 'ADD_ASSET_SUCCESS',
  UPDATE_ASSET: 'UPDATE_ASSET',
  UPDATE_ASSET_SUCCESS: 'UPDATE_ASSET_SUCCESS',
  DELETE_ASSET: 'DELETE_ASSET',
  DELETE_ASSET_SUCCESS: 'DELETE_ASSET_SUCCESS',
});

export const consumablesActionTypes = Object.freeze({
  FETCH_CONSUMABLES: 'FETCH_CONSUMABLES',
  FETCH_CONSUMABLES_SUCCESS: 'FETCH_CONSUMABLES_SUCCESS',
  FETCH_CONSUMABLES_ERROR: 'FETCH_CONSUMABLES_ERROR',
  FETCHAVAILABLE_CONSUMABLES: 'FETCHAVAILABLE_CONSUMABLES',
  FETCH_CONSUMABLE_BY_ID: 'FETCH_CONSUMABLE_BY_ID',
  ADD_CONSUMABLE: 'ADD_CONSUMABLE',
  ADD_CONSUMABLE_SUCCESS: 'ADD_CONSUMABLE_SUCCESS',
  UPDATE_CONSUMABLE: 'UPDATE_CONSUMABLE',
  UPDATE_CONSUMABLE_SUCCESS: 'UPDATE_CONSUMABLE_SUCCESS',
  DELETE_CONSUMABLE: 'DELETE_CONSUMABLE',
  DELETE_CONSUMABLE_SUCCESS: 'DELETE_CONSUMABLE_SUCCESS',
});

export const authActions = Object.freeze({
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ADMIN_SUCCESS: 'LOGIN_ADMIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  REQUEST_RESET: 'REQUEST_RESET',
  PASSWORD_RESET: 'PASSWORD_RESET',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  CHANGE_PASSWORD__REQUESTED: 'CHANGE_PASSWORD__REQUESTED',
  ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
});

export const categoryActions = Object.freeze({
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_ERROR: 'FETCH_CATEGORIES_ERROR',
  FETCH_CATEGORY_ITEMS: 'FETCH_CATEGORY_ITEMS',
  FETCH_CATEGORY_DETAILS: 'FETCH_CATEGORY_DETAILS',
  FETCH_CATEGORY_VENDORS: 'FETCH_CATEGORY_VENDORS',
  FETCH_CATEGORY_ASSETS: 'FETCH_CATEGORY_ASSETS',
  FETCH_CATEGORY_CONSUMABLES: 'FETCH_CATEGORY_CONSUMABLES',
  ADD_CATEGORIES: 'ADD_CATEGORIES',
  ADD_CATEGORY_ASSET: 'ADD_CATEGORY_ASSET',
  ADD_CATEGORY_VENDOR: 'ADD_CATEGORY_VENDOR',
  ADD_CATEGORY_CONSUMABLE: 'ADD_CATEGORY_CONSUMABLE',
  REMOVE_CATEGORY_CONSUMABLE: 'REMOVE_CATEGORY_CONSUMABLE',
  REMOVE_CATEGORY_ASSET: 'REMOVE_CATEGORY_ASSET',
  REMOVE_CATEGORY_VENDOR: 'REMOVE_CATEGORY_VENDOR',
  ADD_CATEGORIES_SUCCESS: 'ADD_CATEGORIES_SUCCESS',
  UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
  UPDATE_CATEGORIES_SUCCESS: 'UPDATE_CATEGORIES_SUCCESS',
  DELETE_CATEGORIES: 'DELETE_CATEGORIES',
  DELETE_CATEGORIES_SUCCESS: 'DELETE_CATEGORIES_SUCCESS',
});

export const departmentActions = Object.freeze({
  FETCH_DEPARTMENTS: 'FETCH_DEPARTMENTS',
  FETCH_DEPARTMENTS_SUCCESS: 'FETCH_DEPARTMENTS_SUCCESS',
  FETCH_BASE_DEPARTMENTS_SUCCESS: 'FETCH_BASE_DEPARTMENTS_SUCCESS',
  FETCH_BRANCH_DEPARTMENTS_SUCCESS: 'FETCH_BRANCH_DEPARTMENTS_SUCCESS',
  FETCH_DEPARTMENTS_ERROR: 'FETCH_DEPARTMENTS_ERROR',
  ADD_DEPARTMENTS: 'ADD_DEPARTMENTS',
  ADD_DEPARTMENTS_SUCCESS: 'ADD_DEPARTMENTS_SUCCESS',
  UPDATE_DEPARTMENTS: 'UPDATE_DEPARTMENTS',
  UPDATE_DEPARTMENTS_SUCCESS: 'UPDATE_DEPARTMENTS_SUCCESS',
  DELETE_DEPARTMENTS: 'DELETE_DEPARTMENTS',
  DELETE_DEPARTMENTS_SUCCESS: 'DELETE_DEPARTMENTS_SUCCESS',
});

export const vendorActions = Object.freeze({
  FETCH_VENDORS: 'FETCH_VENDORS',
  FETCH_VENDORS_SUCCESS: 'FETCH_VENDORS_SUCCESS',
  FETCH_VENDORS_ERROR: 'FETCH_VENDORS_ERROR',
  ADD_VENDORS: 'ADD_VENDORS',
  ADD_VENDOR_CATEGORY: 'ADD_VENDOR_CATEGORY',
  ADD_VENDORS_SUCCESS: 'ADD_VENDORS_SUCCESS',
  UPDATE_VENDORS: 'UPDATE_VENDORS',
  UPDATE_VENDORS_SUCCESS: 'UPDATE_VENDORS_SUCCESS',
  DELETE_VENDORS: 'DELETE_VENDORS',
  DELETE_VENDORS_SUCCESS: 'DELETE_VENDORS_SUCCESS',
});

export const locationActions = Object.freeze({
  FETCH_LOCATION: 'FETCH_LOCATION',
  FETCH_LOCATION_SUCCESS: 'FETCH_LOCATION_SUCCESS',
  FETCH_LOCATION_ERROR: 'FETCH_LOCATION_ERROR',
  FETCH_DEPARTMENT_LOCATION: 'FETCH_DEPARTMENT_LOCATION',
  ADD_LOCATION: 'ADD_LOCATION',
  ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  UPDATE_LOCATION_SUCCESS: 'UPDATE_LOCATION_SUCCESS',
  DELETE_LOCATION: 'DELETE_LOCATION',
  DELETE_LOCATION_SUCCESS: 'DELETE_LOCATION_SUCCESS',
});

export const inventoryActions = Object.freeze({
  FETCH_INVENTORY: 'FETCH_INVENTORY',
  FETCH_INVENTORY_SUCCESS: 'FETCH_INVENTORY_SUCCESS',
  FETCH_INVENTORY_ERROR: 'FETCH_INVENTORY_ERROR',
  FETCH_INVENTORY_DETAILS: 'FETCH_INVENTORY_DETAILS',
  FETCH_INVENTORY_ASSETS: 'FETCH_INVENTORY_ASSETS',
  FETCH_INVENTORY_REQUESTS: 'FETCH_INVENTORY_REQUESTS',
  FETCH_INVENTORY_PROPOSALS: 'FETCH_INVENTORY_PROPOSALS',
  ADD_INVENTORY: 'ADD_INVENTORY',
  ADD_INVENTORY_SUCCESS: 'ADD_INVENTORY_SUCCESS',
  ADD_TO_INVENTORY: 'ADD_TO_INVENTORY',
  UPDATE_INVENTORY: 'UPDATE_INVENTORY',
  UPDATE_INVENTORY_SUCCESS: 'UPDATE_INVENTORY_SUCCESS',
  DELETE_INVENTORY: 'DELETE_INVENTORY',
  DELETE_INVENTORY_SUCCESS: 'DELETE_INVENTORY_SUCCESS',
  DELETE_FROM_INVENTORY: 'DELETE_FROM_INVENTORY',
});

export const requestActions = Object.freeze({
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_REQUEST_SUCCESS: 'FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_ERROR: 'FETCH_REQUEST_ERROR',
  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_DEPARTMENT_REQUEST: 'FETCH_DEPARTMENT_REQUEST',
  ISSUE_REQUEST: 'ISSUE_REQUEST',
  ISSUE_REQUEST_SUCCESS: 'ISSUE_REQUEST_SUCCESS',
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_REQUEST_SUCCESS: 'UPDATE_REQUEST_SUCCESS',
  DELETE_REQUEST: 'DELETE_REQUEST',
  DELETE_REQUEST_SUCCESS: 'DELETE_REQUEST_SUCCESS',
});

export const recommendationActions = Object.freeze({
  FETCH_RECOMMENDATION: 'FETCH_RECOMMENDATION',
  FETCH_RECOMMENDATION_SUCCESS: 'FETCH_RECOMMENDATION_SUCCESS',
  FETCH_RECOMMENDATION_ERROR: 'FETCH_RECOMMENDATION_ERROR',
  FETCH_DEPARTMENT_RECOMMENDATION: 'FETCH_DEPARTMENT_RECOMMENDATION',
  FETCH_USER_RECOMMENDATION: 'FETCH_USER_RECOMMENDATION',
  ADD_RECOMMENDATION: 'ADD_RECOMMENDATION',
  ADD_RECOMMENDATION_SUCCESS: 'ADD_RECOMMENDATION_SUCCESS',
  UPDATE_RECOMMENDATION: 'UPDATE_RECOMMENDATION',
  UPDATE_RECOMMENDATION_SUCCESS: 'UPDATE_RECOMMENDATION_SUCCESS',
  DELETE_RECOMMENDATION: 'DELETE_RECOMMENDATION',
  DELETE_RECOMMENDATION_SUCCESS: 'DELETE_RECOMMENDATION_SUCCESS',
});

export const priorityActions = Object.freeze({
  FETCH_PRIORITY: 'FETCH_PRIORITY',
  FETCH_PRIORITY_SUCCESS: 'FETCH_PRIORITY_SUCCESS',
  FETCH_PRIORITY_ERROR: 'FETCH_PRIORITY_ERROR',
});

export const roleActions = Object.freeze({
  FETCH_ROLES: 'FETCH_ROLES',
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
  FETCH_ROLES_ERROR: 'FETCH_ROLES_ERROR',
});

export const userActions = Object.freeze({
  FETCH_USERS: 'FETCH_USERS',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_ADMINS_SUCCESS: 'FETCH_ADMINS_SUCCESS',
  FETCH_USERS_ERROR: 'FETCH_USERS_ERROR',
  FETCH_BY_USERID: 'FETCH_BY_USERID',
  FETCH_DEPARTMENT_USERS: 'FETCH_DEPARTMENT_USERS',
  ADD_USERS: 'ADD_USERS',
  ADD_USERS_SUCCESS: 'ADD_USERS_SUCCESS',
  BULK_INSERT: 'BULK_INSERT',
  UPDATE_USERS: 'UPDATE_USERS',
  UPDATE_USERS_SUCCESS: 'UPDATE_USERS_SUCCESS',
  DELETE_USERS: 'DELETE_USERS',
  DELETE_USERS_SUCCESS: 'DELETE_USERS_SUCCESS',
});

export const countryActions = Object.freeze({
  FETCH_COUNTRY: 'FETCH_COUNTRY',
  FETCH_COUNTRY_SUCCESS: 'FETCH_COUNTRY_SUCCESS',
  FETCH_COUNTRY_ERROR: 'FETCH_COUNTRY_ERROR',
  ADD_COUNTRY: 'ADD_COUNTRY',
  ADD_COUNTRY_SUCCESS: 'ADD_COUNTRY_SUCCESS',
  UPDATE_COUNTRY: 'UPDATE_COUNTRY',
  UPDATE_COUNTRY_SUCCESS: 'UPDATE_COUNTRY_SUCCESS',
  DELETE_COUNTRY: 'DELETE_COUNTRY',
  DELETE_COUNTRY_SUCCESS: 'DELETE_COUNTRY_SUCCESS',
});

export const subCountryActions = Object.freeze({
  FETCH_SUBCOUNTRY: 'FETCH_SUBCOUNTRY',
  FETCH_SUBCOUNTRY_SUCCESS: 'FETCH_SUBCOUNTRY_SUCCESS',
  FETCH_SUBCOUNTRY_ERROR: 'FETCH_SUBCOUNTRY_ERROR',
  ADD_SUBCOUNTRY: 'ADD_SUBCOUNTRY',
  ADD_SUBCOUNTRY_SUCCESS: 'ADD_SUBCOUNTRY_SUCCESS',
  UPDATE_SUBCOUNTRY: 'UPDATE_SUBCOUNTRY',
  UPDATE_SUBCOUNTRY_SUCCESS: 'UPDATE_SUBCOUNTRY_SUCCESS',
  DELETE_SUBCOUNTRY: 'DELETE_SUBCOUNTRY',
  DELETE_SUBCOUNTRY_SUCCESS: 'DELETE_SUBCOUNTRY_SUCCESS',
});

export const cityActions = Object.freeze({
  FETCH_CITY: 'FETCH_CITY',
  FETCH_CITY_SUCCESS: 'FETCH_CITY_SUCCESS',
  FETCH_CITY_ERROR: 'FETCH_CITY_ERROR',
  ADD_CITY: 'ADD_CITY',
  ADD_CITY_SUCCESS: 'ADD_CITY_SUCCESS',
  UPDATE_CITY: 'UPDATE_CITY',
  UPDATE_CITY_SUCCESS: 'UPDATE_CITY_SUCCESS',
  DELETE_CITY: 'DELETE_CITY',
  DELETE_CITY_SUCCESS: 'DELETE_CITY_SUCCESS',
});

export const currencyActions = Object.freeze({
  FETCH_CURRENCY: 'FETCH_CURRENCY',
  FETCH_CURRENCY_SUCCESS: 'FETCH_CURRENCY_SUCCESS',
  FETCH_CURRENCY_ERROR: 'FETCH_CURRENCY_ERROR',
  ADD_CURRENCY: 'ADD_CURRENCY',
  ADD_CURRENCY_SUCCESS: 'ADD_CURRENCY_SUCCESS',
  UPDATE_CURRENCY: 'UPDATE_CURRENCY',
  UPDATE_CURRENCY_SUCCESS: 'UPDATE_CURRENCY_SUCCESS',
  DELETE_CURRENCY: 'DELETE_CURRENCY',
  DELETE_CURRENCY_SUCCESS: 'DELETE_CURRENCY_SUCCESS',
});

export const tenantActions = Object.freeze({
  FETCH_TENANT: 'FETCH_TENANT',
  FETCH_TENANT_SUCCESS: 'FETCH_TENANT_SUCCESS',
  FETCH_TENANT_ERROR: 'FETCH_TENANT_ERROR',
  FETCH_TENANT_CONFIG: 'FETCH_TENANT_CONFIG',
  FETCH_TENANT_CONFIG_SUCCESS: 'FETCH_TENANT_CONFIG_SUCCESS',
  FETCH_TENANT_CONFIG_ERROR: 'FETCH_TENANT_CONFIG_ERROR',
  ADD_TENANT: 'ADD_TENANT',
  ADD_TENANT_SUCCESS: 'ADD_TENANT_SUCCESS',
  UPDATE_TENANT: 'UPDATE_TENANT',
  UPDATE_TENANT_SUCCESS: 'UPDATE_TENANT_SUCCESS',
  DELETE_TENANT: 'DELETE_TENANT',
  DELETE_TENANT_SUCCESS: 'DELETE_TENANT_SUCCESS',
});

export const dashboardActions = Object.freeze({
  FETCH_DASH: 'FETCH_DASH',
  FETCH_DASH_SUCCESS: 'FETCH_DASH_SUCCESS',
  FETCH_DASH_ERROR: 'FETCH_DASH_ERROR',
});

export const reportActions = Object.freeze({
  FETCH_REPORT: 'FETCH_REPORT',
  FETCH_REPORT_SUCCESS: 'FETCH_REPORT_SUCCESS',
  FETCH_REPORT_ERROR: 'FETCH_REPORT_ERROR',
});

export const logsActions = Object.freeze({
  FETCH_LOGS: 'FETCH_LOGS',
  FETCH_LOGS_SUCCESS: 'FETCH_LOGS_SUCCESS',
  FETCH_LOGS_ERROR: 'FETCH_LOGS_ERROR',
});

export const transferAction = Object.freeze({
  TRANSFER_ASSET: 'TRANSFER_ASSET',
  TRANSFER_ASSET_SUCCESS: 'TRANSFER_ASSET_SUCCESS',
  TRANSFER_ASSET_ERROR: 'TRANSFER_ASSET_ERROR',
  TRANSFER_INVENTORY: 'TRANSFER_INVENTORY',
  TRANSFER_INVENTORY_SUCCESS: 'TRANSFER_INVENTORY_SUCCESS',
  TRANSFER_INVENTORY_ERROR: 'TRANSFER_INVENTORY_ERROR',
});

export const manufacturerActions = Object.freeze({
  FETCH_MANUFACTURERS: 'FETCH_MANUFACTURERS',
  FETCH_MANUFACTURERS_SUCCESS: 'FETCH_MANUFACTURERS_SUCCESS',
  FETCH_MANUFACTURERS_ERROR: 'FETCH_MANUFACTURERS_ERROR',
  ADD_MANUFACTURERS: 'ADD_MANUFACTURERS',
  ADD_MANUFACTURERS_SUCCESS: 'ADD_MANUFACTURERS_SUCCESS',
  UPDATE_MANUFACTURERS: 'UPDATE_MANUFACTURERS',
  UPDATE_MANUFACTURERS_SUCCESS: 'UPDATE_MANUFACTURERS_SUCCESS',
  DELETE_MANUFACTURERS: 'DELETE_MANUFACTURERS',
  DELETE_MANUFACTURERS_SUCCESS: 'DELETE_MANUFACTURERS_SUCCESS',
});

export const subscriptionActions = Object.freeze({
  FETCH_SUBSCRIPTIONS: 'FETCH_SUBSCRIPTIONS',
  FETCH_SUBSCRIPTIONS_UNDER_PACKAGE: 'FETCH_SUBSCRIPTIONS_UNDER_PACKAGE',
  FETCH_SUBSCRIPTIONS_ASSETS: 'FETCH_SUBSCRIPTIONS_ASSETS',

  FETCH_SUBSCRIPTIONS_SUCCESS: 'FETCH_SUBSCRIPTIONS_SUCCESS',
  FETCH_SUBSCRIPTIONS_ERROR: 'FETCH_SUBSCRIPTIONS_ERROR',
  ADD_SUBSCRIPTIONS: 'ADD_SUBSCRIPTIONS',
  ADD_SUBSCRIPTIONS_SUCCESS: 'ADD_SUBSCRIPTIONS_SUCCESS',
  UPDATE_SUBSCRIPTIONS: 'UPDATE_SUBSCRIPTIONS',
  UPDATE_SUBSCRIPTIONS_SUCCESS: 'UPDATE_SUBSCRIPTIONS_SUCCESS',
  DELETE_SUBSCRIPTIONS: 'DELETE_SUBSCRIPTIONS',
  DELETE_SUBSCRIPTIONS_SUCCESS: 'DELETE_SUBSCRIPTIONS_SUCCESS',
});
export const activityActions = Object.freeze({
  FETCH_ACTIVIITES: 'FETCH_ACTIVIITES',
  FETCH_ASSET_ACTIVIITES: 'FETCH_ASSET_ACTIVIITES',
  FETCH_REQUEST_ACTIVIITES: 'FETCH_REQUEST_ACTIVIITES',
  FETCH_PROPOSAL_ACTIVIITES: 'FETCH_PROPOSAL_ACTIVIITES',
  FETCH_INVENTORY_ACTIVIITES: 'FETCH_INVENTORY_ACTIVIITES',
  FETCH_DEPARTMENT_ACTIVIITES: 'FETCH_DEPARTMENT_ACTIVIITES',
  FETCH_CONSUMABLE_ACTIVIITES: 'FETCH_CONSUMABLE_ACTIVIITES',
});

export const subscriptionPackageActions = Object.freeze({
  FETCH_SUBSCRIPTION_PACKAGE: 'FETCH_SUBSCRIPTION_PACKAGE',
  FETCH_SUBSCRIPTION_PACKAGE_INFO: 'FETCH_SUBSCRIPTION_PACKAGE_INFO',
  FETCH_SUBSCRIPTION_PACKAGE_SUCCESS: 'FETCH_SUBSCRIPTION_PACKAGE_SUCCESS',
  FETCH_SUBSCRIPTION_PACKAGE_ERROR: 'FETCH_SUBSCRIPTION_PACKAGE_ERROR',
  ADD_SUBSCRIPTION_PACKAGE: 'ADD_SUBSCRIPTION_PACKAGE',
  ADD_SUBSCRIPTION_PACKAGE_SUCCESS: 'ADD_SUBSCRIPTION_PACKAGE_SUCCESS',
  UPDATE_SUBSCRIPTION_PACKAGE: 'UPDATE_SUBSCRIPTION_PACKAGE',
  UPDATE_SUBSCRIPTION_PACKAGE_SUCCESS: 'UPDATE_SUBSCRIPTION_PACKAGE_SUCCESS',
  DELETE_SUBSCRIPTION_PACKAGE: 'DELETE_SUBSCRIPTION_PACKAGE',
  DELETE_SUBSCRIPTION_PACKAGE_SUCCESS: 'DELETE_SUBSCRIPTION_PACKAGE_SUCCESS',
});

export const envActions = Object.freeze({
  FETCH_ENV_CONFIG: 'FETCH_ENV_CONFIG',
  FETCH_ENV_CONFIG_SUCCESS: 'FETCH_ENV_CONFIG_SUCCESS',
  FETCH_ENV_CONFIG_ERROR: 'FETCH_ENV_CONFIG_ERROR',
  ADD_ENV_CONFIG: 'ADD_ENV_CONFIG',
  ADD_ENV_CONFIG_SUCCESS: 'ADD_ENV_CONFIG_SUCCESS',
  UPDATE_ENV_CONFIG: 'UPDATE_ENV_CONFIG',
  UPDATE_ENV_CONFIG_SUCCESS: 'UPDATE_ENV_CONFIG_SUCCESS',
  DELETE_ENV_CONFIG: 'DELETE_ENV_CONFIG',
  DELETE_ENV_CONFIG_SUCCESS: 'DELETE_ENV_CONFIG_SUCCESS',
});

export const fileActions = Object.freeze({
  FETCH_ALL_FILES: 'FETCH_ALL_FILES',
  FETCH_ASSET_FILES: 'FETCH_ASSET_FILES',
  FETCH_BRANCH_FILES: 'FETCH_BRANCH_FILES',
  FETCH_FILES_SUCCESS: 'FETCH_FILES_SUCCESS',
  FETCH_FILES_ERROR: 'FETCH_FILES_ERROR',
  ADD_FILE: 'ADD_FILE',
  ADD_FILE_SUCCESS: 'ADD_FILE_SUCCESS',
  UPDATE_FILE: 'UPDATE_FILE',
  UPDATE_FILE_SUCCESS: 'UPDATE_FILE_SUCCESS',
  DELETE_FILE: 'DELETE_FILE',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
});

