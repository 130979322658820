import React from 'react';
import NavItem from './navItem/NavItem.jsx';
import { adminMenu } from './menu.config';
import { Tooltip } from 'primereact/tooltip';
import logo from './aiti-logo.png';

const AdminSidebar = props => {
  return (
    <>
      <div className="admin-sidebar">
        <div className="flex justify-content-center mt-2 mb-3">
          <div className="flex align-items-center">
            <img
              // src={process.env.PUBLIC_URL + 'aiti-logo.png'}
              src={logo}
              alt="logo"
              className="w-full h-4rem p-2"
            />
          </div>
        </div>
        {adminMenu.map((item, index) => {
          return (
            <>
              <Tooltip
                target=".tooltip"
                position="right"
                className="blueray-tooltip"
              />
              <span className="tooltip mr-2" data-pr-tooltip={item.label}>
                <NavItem key={`${item.label}-${index}`} item={item} />
              </span>
            </>
          );
        })}
      </div>
    </>
  );
};

export default AdminSidebar;

