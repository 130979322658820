import React, { Component } from 'react';
import { Divider } from 'primereact/divider';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Avatar } from 'primereact/avatar';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import Can from '../../../../shared/casl/can';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  fetchAssetById,
  editAsset,
} from '../../../../shared/redux/actions/assetActions';
import AssetDocuments from '../AssetDocuments';
import AssetActivity from '../AssetActivity';
import AssetSubs from '../AssetSubs';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
class AssetInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: '',
      activeIndex: '',
      disable: true,
      assetDetails: [],
      saveButton: false,
      title: '',
      description: '',
      code: '',
      serial_number: '',
      make: '',
      model: '',
      price: '',
      purchase_date: '',
      warranty_deadline: '',
      service_date: '',
      salvage_price: '',
      lifespan: '',
      dep_factor: '',
      depreciation_algorithm: '',
      asset_id: '',
    };
    this.events1 = [
      {
        status: 'Ordered',
        description: 'Osafo requested for HP Lenovo.',
        date: '15/10/2020 10:30',
        icon: 'pi pi-shopping-cart',
        color: '#9C27B0',
        image: 'game-controller.jpg',
      },
      {
        status: 'Processing',
        description: 'Mary processed HP Lenovo for John.',
        date: '15/10/2020 14:00',
        icon: 'pi pi-cog',
        color: '#673AB7',
      },
      {
        status: 'Shipped',
        description: 'Delilah delivered HP Lenovo to Teddy.',
        date: '15/10/2020 16:15',
        icon: 'pi pi-shopping-cart',
        color: '#FF9800',
      },
      {
        status: 'Delivered',
        description: 'Ama received HP Lenovo.',
        date: '16/10/2020 10:00',
        icon: 'pi pi-check',
        color: '#607D8B',
      },
    ];
    // this.enableInput = this.enableInput.bind(this)
    this.events2 = ['2020', '2021', '2022', '2023'];
    this.depreciationTemplate = this.depreciationTemplate.bind(this);
    this.tabHeaderITemplate = this.tabHeaderITemplate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    await this.props.fetchAssetById(localStorage.assetid);
    let assetDetails = this.props.assetinfo;

    return this.setState({
      asset_id: assetDetails.id,
      title: assetDetails.title,
      description: assetDetails.description,
      code: assetDetails.code,
      serial_number: assetDetails.serial_number,
      make: assetDetails.make,
      model: assetDetails.model,
      price: assetDetails.price,
      purchase_date: assetDetails.purchase_date,
      warranty_deadline: assetDetails.warranty_deadline,
      service_date: assetDetails.service_date,
      salvage_price: assetDetails.salvage_price,
      lifespan: assetDetails.lifespan,
      dep_factor: assetDetails.dep_factor,
      depreciation_algorithm: assetDetails.depreciation_algorithm,
    });
  }
  enableInput(e) {
    this.setState({
      disable: !this.state.disable,
      saveButton: !this.state.saveButton,
    });
  }

  updateAsset() {
    const id = this.state.asset_id;
    const data = {
      title: this.state.title,
      description: this.state.description,
      code: this.state.code,
      serial_number: this.state.serial_number,
      make: this.state.make,
      model: this.state.model,
      price: this.state.price,
      purchase_date: moment(this.state.purchase_date).unix(),
      warranty_deadline: moment(this.state.warranty_deadline).unix(),
      service_date: moment(this.state.service_date).unix(),
      salvage_price: this.state.salvage_price,
      lifespan: this.state.lifespan,
      dep_factor: this.state.dep_factor,
      depreciation_algorithm: this.state.depreciation_algorithm,
    };
    this.props.editAsset(id, data);
  }
  onChange(evt, field) {
    this.setState({ [field]: evt.target.value });
  }

  depreciationTemplate() {
    const data = this.state.assetDetails['depreciation_algorithm'];
    if (data === 'straight_line_depreciation') {
      return 'Straight Line Depreciation';
    } else {
      return 'Declining Balance Depreciation';
    }
  }
  tabHeaderITemplate(options) {
    return (
      <Button
        type="button"
        onClick={options.onClick}
        className={options.className}
        icon={options.leftIcon}
        label={options.titleElement}
      ></Button>
    );
  }

  render() {
    const depreciationOptions = [
      {
        label: 'Straight Line Depreciation',
        value: 'straight_line_depreciation',
      },
      {
        label: 'Declining Balance Depreciation',
        value: 'declining_balance_depreciation',
      },
    ];
    return (
      <div>
        <div class="">
          <div class="flex">
            <div class="flex-initial flex align-items-center justify-content-center  font-bold text-white m-2 px-5 py-3 border-round">
              <Avatar
                icon="pi pi-user"
                className="p-mr-2"
                size="xlarge"
                shape="circle"
              />
            </div>
            <div class="flex-initial flex align-items-center flex-wrap justify-content-center font-bold text-white m-2 px-5 py-3 border-round ">
              <div>
                <div className="font-medium text-3xl text-900">
                  {this.state.title}
                  <span>
                    {' '}
                    <div className="flex align-items-center mt-3">
                      <i className="pi pi-users mr-2"></i>
                      <Chip
                        label="Available"
                        style={{ backgroundColor: '#f9acac' }}
                      />
                    </div>
                  </span>
                </div>

                <div className="flex text-700 flex-wrap">
                  <div className="mr-5 flex align-items-center mt-3">
                    <i className="pi pi-clock mr-2"></i>
                    <span>Model: {this.state.model}</span>
                  </div>
                  <div className="mr-5 flex align-items-center mt-3">
                    <i className="pi pi-globe mr-2"></i>
                    <span>Serial: {this.state.serial_number}</span>
                  </div>
                  <div className="flex align-items-center mt-3">
                    <i className="pi pi-cog mr-2"></i>
                    <span>Code: {this.state.code}</span>
                  </div>
                </div>
              </div>
              <Can do="edit" on="Assets">
                <div className=" flex justify-content-end">
                  {this.state.disable ? (
                    <Button
                      label="Edit"
                      icon="pi pi-pencil"
                      className="p-button-text"
                      onClick={this.enableInput.bind(this)}
                    />
                  ) : (
                    <Button
                      label="Cancel"
                      icon="pi pi-times"
                      className="p-button-text"
                      onClick={this.enableInput.bind(this)}
                    />
                  )}

                  {this.state.saveButton && (
                    <Button
                      label="Save"
                      icon="pi pi-check"
                      id="saveButton"
                      className="p-button-text"
                      onClick={() => this.updateAsset()}
                    />
                  )}
                </div>
              </Can>
            </div>
          </div>
        </div>

        <Divider style={{ width: '82vw' }} />
        <TabView
          style={{
            backgroundColor: 'aliceblue',
            width: '82vw',
            marginLeft: '15px',
            color: 'black',
          }}
        >
          <TabPanel header="Overview" leftIcon="fa fa-arrows-rotate mr-2">
            <div className="surface-0">
              <div className="font-medium text-3xl text-900 mb-3">
                Asset Information
              </div>
              <div className="text-500 mb-5">All Details About the Asset</div>
              <ul className="list-none p-0 m-0" style={{ width: '78vw' }}>
                <li className="flex w-full align-items-center py-3 px-2 border-top-1 border-300 ">
                  <div className="w-full flex sm:flex-row flex-column justify-content-between">
                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">Name</div>
                      <div className="text-900 w-8">
                        <InputText
                          style={{
                            border: 'none',
                            fontSize: '18px',
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                          name="title"
                          id="title"
                          disabled={this.state.disable}
                          value={this.state.title}
                          onChange={e => this.onChange(e, 'title')}
                        />
                      </div>
                    </div>

                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">Code</div>
                      <div className="text-900 w-8 ">
                        {' '}
                        <InputText
                          style={{
                            border: 'none',
                            fontSize: '18px',
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                          name="code"
                          disabled={this.state.disable}
                          value={this.state.code}
                          onChange={e => this.onChange(e, 'code')}
                        />
                      </div>
                    </div>
                  </div>
                </li>

                <li className="flex align-items-center py-3 px-2 border-top-1 border-300">
                  <div className="w-full flex sm:flex-row flex-column justify-content-between">
                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">
                        {' '}
                        Serial No.
                      </div>
                      <div className="text-900 w-8">
                        <InputText
                          style={{
                            border: 'none',
                            fontSize: '18px',
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                          name="serial_number"
                          disabled={this.state.disable}
                          value={this.state.serial_number}
                          onChange={e => this.onChange(e, 'serial_number')}
                        />
                      </div>
                    </div>

                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">Make</div>
                      <div className="text-900 w-8 ">
                        <InputText
                          style={{
                            border: 'none',
                            fontSize: '18px',
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                          name="make"
                          disabled={this.state.disable}
                          value={this.state.make}
                          onChange={e => this.onChange(e, 'make')}
                        />
                      </div>
                    </div>
                  </div>
                </li>
                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 ">
                  <div className="w-full flex sm:flex-row flex-column justify-content-between">
                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">Model</div>
                      <div className="text-900 w-8">
                        <InputText
                          style={{
                            border: 'none',
                            fontSize: '18px',
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                          name="model"
                          disabled={this.state.disable}
                          value={this.state.model}
                          onChange={e => this.onChange(e, 'model')}
                        />
                      </div>
                    </div>

                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">Price</div>
                      <div className="text-900 w-8 ">
                        {this.state.disable ? (
                          <InputText
                            name="price"
                            style={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                              width: '100%',
                            }}
                            disabled={this.state.disable}
                            value={this.state.price}
                          />
                        ) : (
                          <InputNumber
                            value={this.state.price}
                            currency="USD"
                            mode="currency"
                            locale="en-US"
                            inputStyle={{
                              border: 'none',
                              fontSize: '18px',
                              fontWeight: 'bold',
                            }}
                            name="price"
                            onValueChange={e => this.onChange(e, 'price')}
                          ></InputNumber>
                        )}
                      </div>
                    </div>
                  </div>
                </li>

                {/* <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 ">
                  <div className="text-500 w-6 md:w-2 font-medium">
                    Assignee
                  </div>
                  <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                    <Chip label="Gladys Amara" className="mr-2" />
                  </div>
                  <div className="text-500 w-6 md:w-2 font-medium">
                    Availability
                  </div>
                  <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                    <InputText
                      style={{
                        border: 'none',
                        fontSize: '18px',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                      disabled={this.state.disable}
                      value="
                    Not Available"
                    />
                  </div>
                </li> */}

                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 ">
                  <div className="w-full flex sm:flex-row flex-column justify-content-between">
                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">
                        {' '}
                        Purchase Date
                      </div>
                      <div className="text-900 w-8">
                        {this.state.disable ? (
                          <InputText
                            style={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                              width: '100%',
                            }}
                            disabled={this.state.disable}
                            value={moment(this.state.purchase_date).format(
                              'DD-MM-YYYY'
                            )}
                          />
                        ) : (
                          <Calendar
                            disabled={this.state.disable}
                            inputStyle={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                            name="service_date"
                            maxDate={new Date()}
                            value={new Date(this.state.purchase_date)}
                            onChange={e => this.onChange(e, 'purchase_date')}
                          ></Calendar>
                        )}
                      </div>
                    </div>

                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">
                        {' '}
                        Warranty Date
                      </div>
                      <div className="text-900 w-8 ">
                        {this.state.disable ? (
                          <InputText
                            style={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                              width: '100%',
                            }}
                            disabled={this.state.disable}
                            value={moment(this.state.warranty_deadline).format(
                              'DD-MM-YYYY'
                            )}
                          />
                        ) : (
                          <Calendar
                            disabled={this.state.disable}
                            inputStyle={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                            name="service_date"
                            minDate={new Date(this.state.purchase_date)}
                            value={new Date(this.state.warranty_deadline)}
                            onChange={e =>
                              this.onChange(e, 'warranty_deadline')
                            }
                          ></Calendar>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 ">
                  <div className="w-full flex sm:flex-row flex-column justify-content-between">
                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">
                        Service Date
                      </div>
                      <div className="text-900 w-8">
                        {this.state.disable ? (
                          <InputText
                            style={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                              width: '100%',
                            }}
                            disabled={this.state.disable}
                            value={moment(this.state.service_date).format(
                              'DD-MM-YYYY'
                            )}
                          />
                        ) : (
                          <Calendar
                            disabled={this.state.disable}
                            inputStyle={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                            minDate={new Date(this.state.purchase_date)}
                            maxDate={new Date(this.state.warranty_deadline)}
                            name="service_date"
                            value={new Date(this.state.service_date)}
                            onChange={e => this.onChange(e, 'service_date')}
                          ></Calendar>
                        )}
                      </div>
                    </div>

                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">
                        Salvage Amount
                      </div>
                      <div className="text-900 w-8 ">
                        {this.state.disable ? (
                          <InputText
                            name="salvage_price"
                            style={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                              width: '100%',
                            }}
                            disabled={this.state.disable}
                            value={this.state.salvage_price}
                          />
                        ) : (
                          <InputNumber
                            value={this.state.salvage_price}
                            currency="USD"
                            mode="currency"
                            locale="en-US"
                            inputStyle={{
                              border: 'none',
                              fontSize: '18px',
                              fontWeight: 'bold',
                            }}
                            name="salvage_price"
                            onValueChange={e =>
                              this.onChange(e, 'salvage_price')
                            }
                          ></InputNumber>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 ">
                  <div className="w-full flex sm:flex-row flex-column justify-content-between">
                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">
                        Lifespan (Years)
                      </div>
                      <div className="text-900 w-8">
                        {this.state.disable ? (
                          <InputText
                            style={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                              width: '100%',
                            }}
                            disabled={this.state.disable}
                            value={this.state.lifespan}
                          />
                        ) : (
                          <InputNumber
                            value={this.state.lifespan}
                            inputStyle={{
                              border: 'none',
                              fontSize: '18px',
                              fontWeight: 'bold',
                            }}
                            name="lifespan"
                            onValueChange={e => this.onChange(e, 'lifespan')}
                          ></InputNumber>
                        )}
                      </div>
                    </div>

                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">
                        Depreciation (%)
                      </div>
                      <div className="text-900 w-8 ">
                        {this.state.disable ? (
                          <InputText
                            style={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                              width: '100%',
                            }}
                            disabled={this.state.disable}
                            value={this.state.dep_factor}
                          />
                        ) : (
                          <InputNumber
                            value={this.state.dep_factor}
                            inputStyle={{
                              border: 'none',
                              fontSize: '18px',
                              fontWeight: 'bold',
                            }}
                            name="dep_factor"
                            onValueChange={e => this.onChange(e, 'dep_factor')}
                          ></InputNumber>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 ">
                  <div className="w-full flex sm:flex-row flex-column justify-content-between">
                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">
                        Depreciation Type
                      </div>
                      <div className="text-900 w-8">
                        {this.state.disable ? (
                          <InputText
                            style={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                              width: '100%',
                            }}
                            disabled={this.state.disable}
                            value={this.depreciationTemplate()}
                          />
                        ) : (
                          <Dropdown
                            style={{
                              border: 'none',
                              fontSize: '18px',
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                            name="depreciation_algorithm"
                            value={this.state.depreciation_algorithm}
                            options={depreciationOptions}
                            onChange={e =>
                              this.onChange(e, 'depreciation_algorithm')
                            }
                            optionLabel="label"
                          />
                        )}
                      </div>
                    </div>

                    <div className="flex sm:w-6 w-full">
                      <div className="text-500 font-medium w-4 m-3">
                        Description
                      </div>
                      <div className="text-900 w-8 ">
                        <InputTextarea
                          name="description"
                          style={{
                            border: 'none',
                            fontSize: '18px',
                            color: 'black',
                            fontWeight: 'bold',
                            width: '100%',
                          }}
                          disabled={this.state.disable}
                          value={this.state.description}
                          onChange={e => this.onChange(e, 'description')}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </TabPanel>
          <TabPanel header="Subscriptions" leftIcon="pi pi-calendar mr-2">
            <AssetSubs />
          </TabPanel>
          <TabPanel header="Documents" leftIcon="pi pi-calendar mr-2">
            <AssetDocuments disabled={this.state.saveButton} />
          </TabPanel>
          <TabPanel header="Activity Log" leftIcon="pi pi-clock mr-2">
            <AssetActivity />
          </TabPanel>
        </TabView>
      </div>
    );
  }
}
AssetInfo.propTypes = {
  fetchAssetById: PropTypes.func.isRequired,
  assetinfo: PropTypes.array.isRequired,
  editAsset: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  assetinfo: state.assets.assetinfo,
});

export default connect(mapStateToProps, {
  fetchAssetById,
  editAsset,
})(AssetInfo);

