import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import style from './navItem.module.css';
import Can from '../../../casl/can';
import { Desktop } from '../../../utils/mediaQueries';

const resolveLinkPath = (childTo, parentTo) => `${parentTo}${childTo}`;

const NavItemHeader = props => {
  const { item } = props;
  const { label, Icon, to, children } = item;
  const location = useLocation();

  const [expanded, setExpand] = useState();
  // location.pathname.includes(headerToPath)

  const onExpandChange = e => {
    e.preventDefault();
    setExpand(expanded => !expanded);
  };

  return (
    <>
      <button
        className={`${style.navItem} ${style.navItemHeaderButton}`}
        onClick={onExpandChange}
      >
        {Icon}
        <Desktop>
          <span className={style.navLabel}>{label}</span>
        </Desktop>
        {/* <ChevronDownIcon
          className={`${style.navItemHeaderChevron} ${
            expanded && style.chevronExpanded
          }`}
        /> */}
        <i
          className={`pi pi-chevron-down ${style.navItemHeaderChevron} ${
            expanded && style.chevronExpanded
          }`}
        />
      </button>

      {expanded && (
        <div className={style.navChildrenBlock}>
          {children.map((item, index) => {
            const key = `${item.label}-${index}`;

            const { label, Icon, to, children } = item;

            if (children) {
              return (
                <div key={key}>
                  <NavItemHeader
                    item={{
                      ...item,
                      to: to,
                    }}
                  />
                </div>
              );
            }

            return (
              <Can do={item.action} on={item.subject}>
                <NavLink
                  key={key}
                  to={to}
                  className={style.navItem}
                  activeClassName="activeNavItem"

                  // activeClassName={style.activeNavItem}
                >
                  {Icon}

                  {/* <Icon className={style.navIcon} /> */}
                  <Desktop>
                    <span className={style.navLabel}>{label}</span>
                  </Desktop>
                </NavLink>
              </Can>
            );
          })}
        </div>
      )}
    </>
  );
};

export default NavItemHeader;

