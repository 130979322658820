import api from '../../services/api';
import Swal from 'sweetalert2';
import { fileActions } from './types';

export const fetchAllFiles = () => dispatch => {
  return api
    .get('/file-uploads')
    .then(res => res.data)
    .then(file =>
      dispatch({
        type: fileActions.FETCH_FILES_SUCCESS,
        payload: file,
      })
    );
};

export const fetchAssetFiles = () => dispatch => {
  return api
    .get(`/file-uploads/asset/${localStorage.assetid}`)
    .then(res => res.data)
    .then(file =>
      dispatch({
        type: fileActions.FETCH_ASSET_FILES,
        payload: file,
      })
    );
};
export const fetchBranchFiles = id => dispatch => {
  return api
    .get(`/file-uploads/branch/${id}`)
    .then(res => res.data)
    .then(file =>
      dispatch({
        type: fileActions.FETCH_BRANCH_FILES,
        payload: file,
      })
    );
};
export const createFiles = (resource, postData, resource_id) => dispatch => {
  return api
    .post(`/file-uploads/${resource}/${resource_id}`, postData)
    .then(post => {
      if (post.status === 201) {
        dispatch(
          {
            type: fileActions.ADD_FILE_SUCCESS,
            payload: post,
          },
          Swal.fire({
            title: 'File added successfully',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          }).then(function () {
            window.location.reload();
          })
        );
      } else {
        Swal.fire({
          icon: 'error',
          text: post.data,
          title: 'Failed',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    });
};

export const updateFiles =
  (id, resource, resource_id, updateData) => dispatch => {
    return api
      .patch(`/file-uploads/${id}/${resource}/${resource_id}`, updateData)
      .then(update => {
        if (update.status === 200) {
          dispatch(
            {
              type: fileActions.UPDATE_FILE_SUCCESS,
              payload: update,
            },
            Swal.fire({
              icon: 'success',
              title: 'File updated successfully',
              timer: 2000,
              showConfirmButton: false,
            }).then(function () {
              window.location.reload();
            })
          );
        } else {
          Swal.fire({
            icon: 'error',
            text: update.data,
            title: 'Failed',
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
  };

export const disableFiles =
  (id, resource, resource_id, updateData) => dispatch => {
    return api
      .patch(`/file-uploads/${id}/${resource}/${resource_id}`, updateData)
      .then(update => {
        if (update.status === 200) {
          dispatch(
            {
              type: fileActions.DELETE_FILE_SUCCESS,
              payload: update,
            },
            Swal.fire({
              icon: 'success',
              title: 'File has been removed',
              timer: 2000,
              showConfirmButton: false,
            }).then(function () {
              window.location.reload();
            })
          );
        } else {
          Swal.fire({
            icon: 'error',
            text: update.data,
            title: 'Failed',
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
  };

export const deleteFiles = id => dispatch => {
  return api.delete(`/file-uploads/${id}`).then(remove => {
    if (remove.status === 200) {
      dispatch(
        {
          type: fileActions.DELETE_FILE_SUCCESS,
          payload: remove,
        },
        Swal.fire({
          title: 'File deleted successfully',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        }).then(function () {
          window.location.reload();
        })
      );
    } else {
      Swal.fire({
        icon: 'error',
        text: remove.data,
        title: 'Failed',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  });
};

