import React, { Component, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { make_cols } from './columns';
import { SheetJSFT } from './types';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { bulkInsert } from '../../../../../shared/redux/actions/userActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';

const ExcelReader = props => {
  const [file, setFile] = useState({});
  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const handleChange = e => {
    const files = e.target.files;
    if (files && files[0]) setFile(files[0]);
  };

  const onTemplateSelect = e => {
    const files = e.files;
    if (files && files[0]) setFile(files[0]);
  };
  const handleFileUpload = async () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      setData(data);
      setCols(make_cols(ws['!ref']));
      props.bulkInsert(JSON.stringify(data));
      props.handleFunction();
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };
  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };
  const onTemplateClear = () => {
    setTotalSize(0);
  };
  const headerTemplate = options => {
    const { className, chooseButton, uploadButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
        {uploadButton}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={process.env.PUBLIC_URL + 'document.svg'}
            width={60}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <Tag
              value={props.formatSize}
              severity="warning"
              className="px-3 py-2"
            />{' '}
          </span>
        </div>

        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto w-3rem"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
        <span
          style={{
            fontSize: '1.2em',
            color: 'var(--text-color-secondary)',
          }}
          className="mt-5 mb-0"
        >
          Drag and Drop File Here
        </span>
      </div>
    );
  };
  return (
    <div>
      <FileUpload
        ref={fileUploadRef}
        name="demo[]"
        customUpload
        accept="
            .csv,   
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.ms-excel
        "
        maxFileSize={1000000}
        uploadHandler={handleFileUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
      />
    </div>
  );
};

export default connect(null, { bulkInsert })(ExcelReader);

