import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fetchAssetsSubscriptions } from '../../../../shared/redux/actions/subscriptionActions';
import { connect } from 'react-redux';

class AssetSubs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // package: this.props.subscriptionAssets['package']
    };
  }
  componentDidMount() {
    this.props.fetchAssetsSubscriptions();
  }

  render() {
    const packagei = this.props.subscriptionAssets;
    if (packagei.length > 0) {
      return (
        <div>
          <div class="surface-card shadow-2 border-round p-4">
            {packagei?.map(home => (
              <ul class="list-none p-0 m-0">
                <li class="flex flex-column sm:flex-row sm:justify-content-between sm:align-items-center mb-5">
                  <div class="flex align-items-center">
                    <span
                      class="block mr-3 surface-200 border-radius: 10px inline-flex justify-content-center align-items-center"
                      style={{
                        width: '58px',
                        height: '58px',
                        borderRadius: '10px',
                      }}
                    >
                      <img
                        src={home.package.logo}
                        style={{ width: '30px', height: '30px' }}
                        alt="PWD"
                      />
                    </span>
                    <div>
                      <div class="text-900 font-medium text-xl mb-2">
                        {home.package.title}
                      </div>
                      <div class="flex align-items-center">
                        <span class="inline-flex p-1 bg-blue-100 text-blue-600 font-medium text-sm border-round ">
                          {home.package.service_owner_url}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 md:mt-0">
                    {/* <div class="p-rating">
                    <span
                      class="p-rating-icon pi pi-star-fill"
                      tabIndex="0"
                    ></span>
                    <span
                      class="p-rating-icon pi pi-star-fill"
                      tabIndex="0"
                    ></span>
                    <span
                      class="p-rating-icon pi pi-star-fill"
                      tabIndex="0"
                    ></span>
                    <span class="p-rating-icon pi pi-star" tabIndex="0"></span>
                    <span class="p-rating-icon pi pi-star" tabIndex="0"></span>
                  </div> */}

                    <div class="text-sm text-600 mt-2">
                      <i className="pi pi-phone mr-2"></i>
                      {home.package.service_owner_phone}
                    </div>
                    <div class="p-1 bg-green-100 text-green-600 font-medium text-sm border-round text-600 mt-2">
                      <span> GHS {home.price}</span>
                    </div>
                  </div>
                </li>
              </ul>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div class="surface-card shadow-2 border-round p-4">
          <h3 class="text-500  font-medium">No subscriptions for this item</h3>
        </div>
      );
    }
  }
}

AssetSubs.propTypes = {
  fetchAssetsSubscriptions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  subscriptionAssetsPackage: state.subscriptions.subscriptionAssetsPackage,

  subscriptionAssets: state.subscriptions.subscriptionAssets,
});
export default connect(mapStateToProps, { fetchAssetsSubscriptions })(
  AssetSubs
);

