import React, { Component } from 'react';
import CardDemo from '../../../../shared/components/card/CardDemo';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import '../../../../shared/components/Table/Table.css';
import { connect } from 'react-redux';
import {
  fetchRequests,
  editRequest,
  fetchDepartmentRequests,
  fetchConRequests,
  fetchDepartmentConRequests,
  editRequestTrans,
} from '../../../../shared/redux/actions/requestActions';
import PropTypes from 'prop-types';
import Can from '../../../../shared/casl/can';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { InputTextarea } from 'primereact/inputtextarea';
import {
  dateBodyTemplate,
  priorityBodyTemplate,
  statusBodyTemplate,
  monthNavigatorTemplate,
  yearNavigatorTemplate,
} from './const';
import TableUI from '../../../../shared/components/Table/Table';

class RequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggler: false,
      toggler1: false,
      toggler2: false,
      consuToggler: false,
      readyToggler: false,
      pickedToggler: false,
      returnedToggler: false,
      showasset: true,
      showinventory: false,
      info: [],
      reqde: [],
      reqpr: [],
      asset_id: '',
      user_id: '',
      center_code: '',
      startdate: '',
      enddate: '',
      request_status: '',
      assetname: '',
      date_requested: '',
      stat: '',
      globalFilter: '',
      tdate: '',
      mdate: '',
      pdate: '',
      pickup_deadline: '',
      return_deadline: '',
      date_picked: '',
      pickup_date: '',
      return_date: '',
      date_returned: '',
      loading: false,
      totalRecords: 0,
      justification: '',
      infoBc: [],
      infoCb: {},
      infoOb: [],
      infoAsset: [],
      infoConsumable: [],
      infoPriority: [],
      infoObject: [],
      depreqs: [],
    };
    this.hideComponent = this.hideComponent.bind(this);

    this.toggle = this.toggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.reset = this.reset.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);

    // this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    console.log(JSON.parse(localStorage.role));
  }

  async toggle(toggler, rowData) {
    let togglerStatus = this.state[toggler];
    this.setState({ infoCb: rowData }); //check the status of the toggle you clicked
    await this.setState({
      info: rowData,
      infoBc: rowData.object,
      infoOb: rowData.object.consumable,
      // infoCb: rowData,

      reqde: rowData.item,
      reqpr: rowData.priority,
      mdate: moment(rowData.end_date).format('YYYY-MM-DD'),
      tdate: moment(rowData.start_date).format('YYYY-MM-DD'),
      pdate: moment(rowData.pickup_date).format('YYYY-MM-DD'),
    });
    return this.setState({
      [toggler]: !togglerStatus,
    });
  }

  async toggleOpen(toggler, rowData) {
    let togglerStatus = this.state[toggler];

    await this.setState({
      info: rowData,
      infoAsset: rowData.object['asset'] || [],
      infoConsumable: rowData.object['consumable'] || [],
      infoPriority: rowData.priority,
      infoObject: rowData.object,
    });
    console.log(this.state.infoObject);
    return this.setState({ [toggler]: !togglerStatus });
  }

  handleChange(evt, field) {
    // check it out: we get the evt.target.title (which will be either "email" or "password")
    // and use it to target the key on our `state` object with the same title, using bracket syntax
    this.setState({ [field]: evt.target.value });
  }

  handleClose() {
    this.setState({ toggler: false });
    this.setState({ toggler1: false });
    this.setState({ toggler2: false });
    this.setState({ consuToggler: false });

    this.setState({ readyToggler: false });
    this.setState({ pickedToggler: false });
    this.setState({ returnedToggler: false });
  }

  reset() {
    this.setState({ globalFilter: '' });
    this.dt.reset();
  }

  infoDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
    </React.Fragment>
  );

  acceptDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.handleClose();
          this.verifyRequest();
        }}
      />
    </React.Fragment>
  );

  readyDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.handleClose();
          this.readyRequest();
        }}
      />
    </React.Fragment>
  );

  pickedDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.handleClose();
          this.pickedRequest();
        }}
      />
    </React.Fragment>
  );
  returnedDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.handleClose();
          this.returnedRequest();
        }}
      />
    </React.Fragment>
  );
  declineDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.handleClose();
          this.declinedRequest();
        }}
      />
    </React.Fragment>
  );

  deleteRequestDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        // onClick={hideDeleteRequestDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        // onClick={deleteRequest}
      />
    </React.Fragment>
  );

  deleteRequestsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        // onClick={hideDeleteRequestsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        // onClick={deleteSelectedRequests}
      />
    </React.Fragment>
  );

  verifyRequest() {
    const id = this.state.info.id;
    const updateData = {
      status: this.state.stat,
      department_id: this.props.user.department_id,
      justication: this.state.info.justication,
      // inventory_id: 7,
    };
    // console.log(id, updateData);
    this.props.editRequest(updateData, id);
  }

  declinedRequest() {
    const id = this.state.info.id;
    const updateData = {
      status: this.state.stat,
      department_id: this.props.user.department_id,
      justification: this.state.justification,
    };
    // console.log(id,updateData)
    this.props.editRequest(updateData, id);
  }

  readyRequest() {
    const id = this.state.info.id;
    // console.log(this.state.info.object.start_date);
    const updateData = {
      action: 'ready',
      pickup_deadline: moment(this.state.pickup_deadline).unix(),
      // return_deadline: moment(this.state.return_deadline).unix(),
    };
    // console.log(updateData);
    this.props.editRequestTrans(updateData, id);
  }

  pickedRequest() {
    const id = this.state.info.id;
    const updateData = {
      action: 'picked',
      picked_at: this.state.pickup_date / 1000,
    };
    this.props.editRequestTrans(updateData, id);
  }

  returnedRequest() {
    const id = this.state.info.id;
    const updateData = {
      action: 'returned',
      returned_at: this.state.return_date / 1000,
    };
    this.props.editRequestTrans(updateData, id);
  }

  hideComponent(name) {
    switch (name) {
      case 'showasset':
        this.setState({ showasset: !this.state.showasset });
        this.setState({ showinventory: false });
        break;
      case 'showinventory':
        this.setState({ showinventory: !this.state.showinventory });
        this.setState({ showasset: false });
        break;
      default:
        this.setState({ showasset: false, showinventory: false });
    }
  }
  render() {
    const { showasset, showinventory } = this.state;

    const actionBodyTemplate = rowData => {
      let action = rowData.object?.action;
      if (rowData.status === 'active') {
        return (
          <React.Fragment>
            <Can do="detail" on="Requests">
              <Button
                icon="pi pi-info"
                className="p-button-rounded p-button-info m-2"
                tooltip="More Info"
                tooltipOptions={{ position: 'bottom' }}
                onClick={() => this.toggleOpen('toggler', rowData)}
              ></Button>
            </Can>
            <Can do="verify" on="Requests">
              <Button
                onClick={() => {
                  this.toggleOpen('toggler1', rowData);
                  this.setState({ stat: 'accepted' });
                }}
                icon="pi pi-check"
                className="p-button-rounded p-button-success m-2"
                tooltip="Accept Request"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
              <Button
                onClick={() => {
                  this.toggleOpen('toggler2', rowData);
                  this.setState({ stat: 'declined' });
                }}
                icon="pi pi-times"
                className="p-button-rounded p-button-warning m-2"
                tooltip="Decline Request"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
            </Can>
          </React.Fragment>
        );
      } else if (
        rowData.status === 'accepted' &&
        action === 'ready'
        //  && rowData.object?.action === 'ready'
      ) {
        return (
          <React.Fragment>
            <Can do="detail" on="Requests">
              <Button
                onClick={() => this.toggleOpen('toggler', rowData)}
                icon="pi pi-info"
                className="p-button-rounded p-button-info mr-2"
                tooltip="More Info"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
            </Can>
            <Can do="verify" on="Requests">
              <Button
                onClick={() => this.toggleOpen('pickedToggler', rowData)}
                icon="pi pi-check-circle"
                className="p-button-rounded p-button-success mr-2"
                tooltip="Set Date Picked"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
            </Can>
          </React.Fragment>
        );
      } else if (
        rowData.status === 'accepted' &&
        action === 'picked'
        // &&
        // rowData.object.action === 'picked'
      ) {
        return (
          <React.Fragment>
            <Can do="detail" on="Requests">
              <Button
                onClick={() => this.toggleOpen('toggler', rowData)}
                icon="pi pi-info"
                className="p-button-rounded p-button-info mr-2"
                tooltip="More Info"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
            </Can>
            <Can do="verify" on="Requests">
              <Button
                onClick={() => this.toggleOpen('returnedToggler', rowData)}
                icon="pi pi-arrow-circle-left"
                className="p-button-rounded p-button-secondary mr-2"
                tooltip="Set Date Returned"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
            </Can>
          </React.Fragment>
        );
      } else if (
        rowData.status === 'accepted' &&
        action === 'returned'
        // &&
        //   rowData.object.action === 'returned'
      ) {
        return (
          <React.Fragment>
            <Can do="detail" on="Requests">
              <Button
                onClick={() => this.toggleOpen('toggler', rowData)}
                icon="pi pi-info"
                className="p-button-rounded p-button-info mr-2"
                tooltip="More Info"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
            </Can>
          </React.Fragment>
        );
      } else if (rowData.status === 'accepted') {
        return (
          <React.Fragment>
            <Can do="detail" on="Requests">
              <Button
                onClick={() => this.toggleOpen('toggler', rowData)}
                icon="pi pi-info"
                className="p-button-rounded p-button-info mr-2"
                tooltip="More Info"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
            </Can>
            <Can do="verify" on="Requests">
              <Button
                onClick={() => this.toggleOpen('readyToggler', rowData)}
                icon="pi pi-arrow-circle-right"
                className="p-button-rounded p-button-secondary mr-2"
                tooltip="Set as Ready for pickup"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
            </Can>
          </React.Fragment>
        );
      } else if (rowData.status === 'declined') {
        return (
          <React.Fragment>
            <Can do="detail" on="Requests">
              <Button
                onClick={() => this.toggleOpen('toggler', rowData)}
                icon="pi pi-info"
                className="p-button-rounded p-button-info mr-2"
                tooltip="More Info"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
            </Can>
          </React.Fragment>
        );
      } else if (rowData.status === 'expired') {
        return (
          <React.Fragment>
            <Can do="detail" on="Requests">
              <Button
                onClick={() => this.toggleOpen('toggler', rowData)}
                icon="pi pi-info"
                className="p-button-rounded p-button-info mr-2"
                tooltip="More Info"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
            </Can>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Can do="detail" on="Requests">
              <Button
                onClick={() => this.toggleOpen('toggler', rowData)}
                icon="pi pi-info"
                className="p-button-rounded p-button-info mr-2"
                tooltip="More Info"
                tooltipOptions={{ position: 'bottom' }}
              ></Button>
            </Can>
          </React.Fragment>
        );
      }
    };

    const actionConBodyTemplate = rowData => {
      switch (rowData.status) {
        case 'active':
          return (
            <React.Fragment>
              <Can do="detail" on="Requests">
                <Button
                  icon="pi pi-info"
                  className="p-button-rounded p-button-info m-2"
                  tooltip="More Info"
                  tooltipOptions={{ position: 'bottom' }}
                  onClick={() => this.toggleOpen('consuToggler', rowData)}
                ></Button>
              </Can>
              <Can do="verify" on="Requests">
                <Button
                  onClick={() => {
                    this.toggleOpen('toggler1', rowData);
                    this.setState({ stat: 'accepted' });
                  }}
                  icon="pi pi-check"
                  className="p-button-rounded p-button-success m-2"
                  tooltip="Accept Request"
                  tooltipOptions={{ position: 'bottom' }}
                ></Button>
                <Button
                  onClick={() => {
                    this.toggleOpen('toggler2', rowData);
                    this.setState({ stat: 'declined' });
                  }}
                  icon="pi pi-times"
                  className="p-button-rounded p-button-warning m-2"
                  tooltip="Decline Request"
                  tooltipOptions={{ position: 'bottom' }}
                ></Button>
              </Can>
            </React.Fragment>
          );

        case 'accepted':
          return (
            <React.Fragment>
              <Can do="detail" on="Requests">
                <Button
                  onClick={() => this.toggleOpen('toggler', rowData)}
                  icon="pi pi-info"
                  className="p-button-rounded p-button-info mr-2"
                  tooltip="More Info"
                  tooltipOptions={{ position: 'bottom' }}
                ></Button>
              </Can>
            </React.Fragment>
          );
        case 'declined':
          return (
            <React.Fragment>
              <Can do="detail" on="Requests">
                <Button
                  onClick={() => this.toggleOpen('toggler', rowData)}
                  icon="pi pi-info"
                  className="p-button-rounded p-button-info mr-2"
                  tooltip="More Info"
                  tooltipOptions={{ position: 'bottom' }}
                ></Button>
              </Can>
            </React.Fragment>
          );

        case 'expired':
          return (
            <React.Fragment>
              <Can do="detail" on="Requests">
                <Button
                  onClick={() => this.toggleOpen('toggler', rowData)}
                  icon="pi pi-info"
                  className="p-button-rounded p-button-info mr-2"
                  tooltip="More Info"
                  tooltipOptions={{ position: 'bottom' }}
                ></Button>
              </Can>
            </React.Fragment>
          );
        default:
          return (
            <React.Fragment>
              <Can do="detail" on="Requests">
                <Button
                  onClick={() => this.toggleOpen('toggler', rowData)}
                  icon="pi pi-info"
                  className="p-button-rounded p-button-info mr-2"
                  tooltip="More Info"
                  tooltipOptions={{ position: 'bottom' }}
                ></Button>
              </Can>
            </React.Fragment>
          );
      }
    };

    const requestColumns = [
      { field: 'object.asset.title', header: 'Asset name' },
      { field: 'object.asset.serial_number', header: 'Asset code' },
      { field: 'created_at', header: 'Date Requested', body: dateBodyTemplate },
      {
        field: 'priority.title',
        header: 'Priority',
        body: priorityBodyTemplate,
      },
      { field: 'status', header: 'Status', body: statusBodyTemplate },
      { header: 'Action(s)', body: actionBodyTemplate },
    ];

    const requestConColumns = [
      { field: 'object.consumable.title', header: 'Item name' },
      { field: 'object.quantity', header: 'Quantity requested' },
      {
        field: 'created_at',
        header: 'Date Requested',
        body: dateBodyTemplate,
      },
      {
        field: 'priority.title',
        header: 'Priority',
        body: priorityBodyTemplate,
      },
      { field: 'status', header: 'Status', body: statusBodyTemplate },
      { header: 'Action(s)', body: actionConBodyTemplate },
    ];

    const opened = this.props.requests.filter(m => m.action !== 'completed');
    const accepted = this.props.requests.filter(m => m.action === 'accepted');
    const created = this.props.requests.filter(m => m.action === 'created');
    const settledReqs = this.props.requests.filter(
      m => m.action === 'completed'
    );
    return (
      <div>
        <h2
          className="p-mb-3 p-text-bold"
          style={{ marginLeft: '20px', color: '#495057' }}
        >
          Request List
        </h2>
        <br></br>

        <div className="p-grid p-justify-between cardFstyle">
          <Can do="view" on="PendingS">
            <div className="p-col-7 p-md-3 p-lg-2">
              <CardDemo
                title="Pending requests"
                icon="pi pi-folder-open"
                content={accepted.length}
                color="#cae6fc"
                iconColor="#2196f3"
                update="1"
              ></CardDemo>
            </div>
          </Can>
          <Can do="view" on="PendingH">
            <div className="p-col-7 p-md-3 p-lg-2">
              <CardDemo
                title="Pending requests"
                icon="pi pi-folder-open"
                content={created.length}
                color="#cae6fc"
                iconColor="#2196f3"
                update="1"
              ></CardDemo>
            </div>
          </Can>
          <div className="p-col-7 p-md-3 p-lg-2">
            <CardDemo
              title="Open requests"
              icon="pi pi-folder-open"
              content={opened.length}
              color="#e7cbec"
              iconColor="#9c27b0"
              update="1"
            ></CardDemo>
          </div>
          <div className="p-col-7 p-md-3 p-lg-2">
            <CardDemo
              title="Settled requests"
              icon="pi pi-folder"
              content={settledReqs.length}
              update="1"
              color="#fde0c2"
              iconColor="#f57c00"
            ></CardDemo>
          </div>
        </div>
        <br></br>
        <div style={{ marginLeft: '20px' }}>
          <Button
            icon="pi pi-shopping-cart"
            label="Assets"
            onClick={() => this.hideComponent('showasset')}
          ></Button>
          &nbsp; &nbsp;
          <Button
            icon="pi pi-table"
            label="Consumables"
            onClick={() => this.hideComponent('showinventory')}
          ></Button>
        </div>
        <br></br>
        <div className="datatable-responsive-demo">
          {showasset && (
            <div>
              <TableUI
                tableHeader="Manage Requests"
                columns={requestColumns}
                fetchFunction={this.props.fetchRequests}
                fetchFunction1={this.props.fetchDepartmentRequests}
                style={{
                  width: '76vw',
                  marginLeft: '15px',
                  marginBottom: '0px',
                  marginTop: '0px',
                }}
              />
            </div>
          )}

          {showinventory && (
            <div className="datatable-responsive-demo">
              <TableUI
                tableHeader="Manage Consumable Requests"
                columns={requestConColumns}
                fetchFunction={this.props.fetchConRequests}
                fetchFunction1={this.props.fetchDepartmentConRequests}
                style={{
                  width: '76vw',
                  marginLeft: '15px',
                  marginBottom: '0px',
                  marginTop: '0px',
                }}
              />
            </div>
          )}
          <Dialog
            draggable={false}
            visible={this.state['toggler']}
            style={{ width: '45vw' }}
            header="Asset Request Details"
            modal
            className="p-fluid"
            footer={this.infoDialogFooter}
            onHide={this.handleClose}
          >
            <div className="formgrid grid">
              <div className="field col-4">
                <label htmlFor="namefItem" className="block font-normal">
                  Asset name
                </label>
                <InputText value={this.state.infoAsset.title} disabled />
              </div>
              <div className="field col-4">
                <label htmlFor="namefItem" className="block font-normal">
                  Serial number
                </label>
                <InputText
                  value={this.state.infoAsset.serial_number}
                  disabled
                />
              </div>
              <div className="field col-4">
                <label htmlFor="namefItem" className="block font-normal">
                  Asset make
                </label>
                <InputText value={this.state.infoAsset.make} disabled />
              </div>

              <div className="field col-4">
                <label htmlFor="namefItem" className="block font-normal">
                  Date Requested
                </label>
                <InputText
                  value={moment(this.state.info.created_at).format(
                    'YYYY/MM/DD'
                  )}
                  disabled
                />
              </div>

              <div className="field col-4">
                <label htmlFor="namefItem" className="block font-normal">
                  Start Date
                </label>
                <InputText
                  value={moment(this.state.info.start_date).format(
                    'YYYY/MM/DD'
                  )}
                  disabled
                />
              </div>
              <div className="field col-4">
                <label htmlFor="namefItem" className="block font-normal">
                  End Date
                </label>
                <InputText
                  value={moment(this.state.info.end_date).format('YYYY/MM/DD')}
                  disabled
                />
              </div>
              <div className="field col-4">
                <label htmlFor="namefItem" className="block font-normal">
                  Priority
                </label>
                <InputText value={this.state.infoPriority.title} disabled />
              </div>
              <div className="field col-4">
                <label htmlFor="namefItem" className="block font-normal">
                  Pickup Date
                </label>
                <InputText
                  value={moment(this.state.info.pickup_date).format(
                    'YYYY/MM/DD'
                  )}
                  disabled
                />
              </div>
              <div className="field col-4">
                <label htmlFor="namefItem" className="block font-normal">
                  Return Date
                </label>
                <InputText
                  value={moment(this.state.info.return_date).format(
                    'YYYY/MM/DD'
                  )}
                  disabled
                />
              </div>
              <div className="field col-12">
                <label htmlFor="namefItem" className="block font-normal">
                  Justification
                </label>
                <InputTextarea value={this.state.info.justification} disabled />
              </div>
            </div>
          </Dialog>

          <Dialog
            draggable={false}
            visible={this.state['consuToggler']}
            style={{ width: '45vw' }}
            header="Consumable Request Details"
            modal
            className="p-fluid"
            footer={this.infoDialogFooter}
            onHide={this.handleClose}
          >
            <div className="formgrid grid">
              <div className="field col-12">
                <label htmlFor="namefItem" className="block font-normal">
                  Item name
                </label>
                <InputText value={this.state.infoConsumable.title} disabled />
              </div>

              <div className="field col-12">
                <label htmlFor="namefItem" className="block font-normal">
                  Item Description
                </label>
                <InputTextarea
                  value={this.state.infoConsumable.description}
                  disabled
                />
              </div>
              <div className="field col-6">
                <label htmlFor="namefItem" className="block font-normal">
                  Date Requested
                </label>
                <InputText
                  value={moment(this.state.info.created_at).format(
                    'YYYY/MM/DD'
                  )}
                  disabled
                />
              </div>

              <div className="field col-6">
                <label htmlFor="namefItem" className="block font-normal">
                  Start Date
                </label>
                <InputText
                  value={moment(this.state.info.start_date).format(
                    'YYYY/MM/DD'
                  )}
                  disabled
                />
              </div>

              <div className="field col-6">
                <label htmlFor="namefItem" className="block font-normal">
                  Priority
                </label>
                <InputText value={this.state.infoPriority.title} disabled />
              </div>
              <div className="field col-6">
                <label htmlFor="namefItem" className="block font-normal">
                  Pickup Date
                </label>
                <InputText
                  value={moment(this.state.info.pickup_date).format(
                    'YYYY/MM/DD'
                  )}
                  disabled
                />
              </div>
            </div>
          </Dialog>

          <Dialog
            draggable={false}
            visible={this.state['toggler1']}
            style={{ width: '35vw' }}
            header="Accept Asset Request"
            modal
            className="p-fluid"
            footer={this.acceptDialogFooter}
            onHide={this.handleClose}
          >
            <div className="confirmation-content">
              <i className="pi pi-check mr-2" style={{ fontSize: '2rem' }} />
              {this.state.infoAsset && (
                <span>
                  Accept Request For <b>{this.state.infoAsset.title}</b>?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            draggable={false}
            visible={this.state['toggler2']}
            style={{ width: '30vw' }}
            header="Decline Request"
            modal
            className="p-fluid"
            footer={this.declineDialogFooter}
            onHide={this.handleClose}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle mr-2"
                style={{ fontSize: '1rem' }}
              />
              {this.state.infoAsset && (
                <span>
                  Decline Request For <b>{this.state.infoAsset.title}</b>
                </span>
              )}
            </div>
            <br></br>
            <div className="p-formgrid p-grid">
              <div className="p-field p-col">
                <label htmlFor="pickup_date" className="block font-normal">
                  Justification
                </label>
                <InputTextarea
                  id="justification"
                  name="justification"
                  onChange={event => this.handleChange(event, 'justification')}
                  value={this.state.justification}
                />
              </div>
            </div>
          </Dialog>

          <Dialog
            draggable={false}
            visible={this.state['readyToggler']}
            style={{ width: '40vw' }}
            header="Ready Request"
            modal
            className="p-fluid"
            footer={this.readyDialogFooter}
            onHide={this.handleClose}
          >
            <div className="formgrid grid">
              <div className="field col-12">
                <label htmlFor="pickup_date" className="block font-normal">
                  Pickup Deadline
                </label>
                <Calendar
                  id="pickup_deadline"
                  dateFormat="dd/mm/yy"
                  minDate={new Date(this.state.infoObject?.start_date)}
                  // maxDate={new Date(this.state.infoObject?.end_date)}
                  onChange={event =>
                    this.handleChange(event, 'pickup_deadline')
                  }
                  value={this.state.pickup_deadline}
                  name="pickup_deadline"
                  placeholder="Pickup Date"
                  monthNavigator
                  showIcon
                  yearNavigator
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                  yearRange="2010:2050"
                />
              </div>
              <div className="field col-12">
                <label htmlFor="return_deadline" className="block font-normal">
                  Return Deadline
                </label>
                <Calendar
                  id="return_deadline"
                  dateFormat="dd/mm/yy"
                  minDate={new Date(this.state.pickup_deadline)}
                  maxDate={new Date(this.state.mdate)}
                  value={this.state.return_deadline}
                  onChange={event =>
                    this.handleChange(event, 'return_deadline')
                  }
                  name="return_deadline"
                  placeholder="Return Date"
                  showIcon
                  monthNavigator
                  yearNavigator
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                  yearRange="2010:2050"
                />
              </div>
            </div>
          </Dialog>

          <Dialog
            draggable={false}
            visible={this.state['pickedToggler']}
            style={{ width: '35vw' }}
            header="Set Date Item was Picked"
            modal
            className="p-fluid"
            footer={this.pickedDialogFooter}
            onHide={this.handleClose}
          >
            <div className="confirmation-content">
              <div className="field col-12">
                <label htmlFor="pickup_date" className="block font-normal">
                  Date Picked
                </label>
                <Calendar
                  id="pickup_date"
                  dateFormat="dd/mm/yy"
                  minDate={new Date(this.state.infoObject?.start_date)}
                  value={this.state.pickup_date}
                  // maxDate={new Date(this.state.mdate)}
                  onChange={event => this.handleChange(event, 'pickup_date')}
                  name="pickup_date"
                  placeholder="Date Picked"
                  showIcon
                  monthNavigator
                  yearNavigator
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                  yearRange="2010:2050"
                />
              </div>
            </div>
          </Dialog>

          <Dialog
            draggable={false}
            visible={this.state['returnedToggler']}
            style={{ width: '35vw' }}
            header="Set Date Item was Returned"
            modal
            className="p-fluid"
            footer={this.returnedDialogFooter}
            onHide={this.handleClose}
          >
            <div className="confirmation-content">
              <div className="field col-12">
                <label htmlFor="return_date" className="block font-normal">
                  Date Returned
                </label>
                <Calendar
                  id="return_date"
                  dateFormat="dd/mm/yy"
                  minDate={new Date(this.state.infoObject?.start_date)}
                  value={this.state.return_date}
                  // maxDate={new Date(this.state.infoObject?.end_date)}
                  onChange={event => this.handleChange(event, 'return_date')}
                  name="return_date"
                  placeholder="Date Returned"
                  monthNavigator
                  showIcon
                  yearNavigator
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
                  yearRange="2010:2050"
                />
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

RequestList.propTypes = {
  fetchRequests: PropTypes.func.isRequired,
  editRequest: PropTypes.func.isRequired,
  requests: PropTypes.array.isRequired,
  fetchDepartmentRequests: PropTypes.func.isRequired,
  departmentrequests: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  requests: state.requests.requests,
  user: state.auth.user,
  pagesize: state.requests.pagesize,
  departmentrequests: state.requests.departmentrequests,
});
export default connect(mapStateToProps, {
  fetchDepartmentRequests,
  fetchConRequests,
  fetchDepartmentConRequests,
  fetchRequests,
  editRequest,
  editRequestTrans,
})(RequestList);

