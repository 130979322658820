import { Button } from 'primereact/button';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function NoMatch() {
  let location = useLocation();
  const navigate = useNavigate();
  return (
    // <div>
    //   <h3>
    //     No match for <code>{location.pathname}</code>
    //     <button onClick={() => navigate(-1)}>Go back</button>
    //   </h3>
    // </div>

    <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div className="bg-404 text-center">
        <span className="bg-white text-pink-500 font-bold text-2xl inline-block px-3">
          404
        </span>
      </div>
      <div className="mt-6 mb-5 font-bold text-6xl text-900 text-center">
        Page Not Found
      </div>
      <p className="text-700 text-3xl mt-0 mb-6 text-center">
        Sorry, we couldn't find the page. {location.pathname}
      </p>
      <div className="text-center">
        <Button
          type="button"
          className="p-button-text mr-2"
          label="Go Back"
          icon="pi pi-arrow-left"
          onClick={() => navigate(-1)}
        ></Button>
        <Button
          type="button"
          label="Go to Home"
          icon="pi pi-home"
          onClick={() => navigate('/')}
        ></Button>
      </div>
    </div>
  );
}
export default NoMatch;

