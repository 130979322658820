import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import CardDemo from '../../../../shared/components/card/CardDemo';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { connect } from 'react-redux';
import DataTableDynamicDemo from '../../../../shared/components/Table/AdTable';
import { dateBodyTemplate } from '../Tenant/const';
import {
  fetchAdmins,
  createAdmin,
} from '../../../../shared/redux/actions/userActions';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { AdminSchema } from '../../../../shared/utils/validation';

class PermissionsManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggler: false,
      updateToggler: false,
      toggler2: false,
      infoToggler: false,
      fileToggler: false,
      info: [],
      details: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleInfoOpen = this.handleInfoOpen.bind(this);
  }

  handleClose() {
    this.setState({ toggler: false });
    this.setState({ updateToggler: false });
    this.setState({ toggler2: false });
    this.setState({ infoToggler: false });
    this.setState({ fileToggler: false });
  }
  async toggle(toggler, rowData) {
    let togglerStatus = this.state[toggler]; //check the status of the toggle you clicked
    await this.setState({
      info: rowData,
      usero: rowData.role,
    });
    return this.setState({
      [toggler]: !togglerStatus,
    });
  }

  async handleInfoOpen(rowData) {
    await this.props.fetchByUserId(rowData.id);
    return this.setState({ updateToggler: true, userd: this.props.userdetail });
  }

  handleChange(evt, field) {
    // check it out: we get the evt.target.title (which will be either "email" or "password")
    // and use it to target the key on our `state` object with the same title, using bracket syntax
    this.setState({ [field]: evt.target.value });
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  adduserDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        form="postform"
      />
    </React.Fragment>
  );

  infoDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
    </React.Fragment>
  );

  deleteuserDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.disableUser();
          this.handleClose();
        }}
      />
    </React.Fragment>
  );

  edituserDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.updateUser();
          this.handleClose();
        }}
      />
    </React.Fragment>
  );
  headerData = (
    <React.Fragment>
      <Button
        label="Admin"
        icon="pi pi-plus"
        className="p-button-raised p-button-outlined h-3rem"
        tooltipOptions={{ position: 'bottom' }}
        tooltip="Create New Administrator"
        onClick={() => this.handleOpen('toggler')}
      />
    </React.Fragment>
  );
  render() {
    const initialValues = {
      email: '',
      password: '',
    };

    const actionBodyTemplate = rowData => {
      return (
        <React.Fragment>
          <Button
            onClick={() => this.toggle('infoToggler', rowData)}
            icon="pi pi-info"
            className="p-button-rounded p-button-info mr-2"
            tooltip="More Info"
            tooltipOptions={{ position: 'bottom' }}
          ></Button>

          <Button
            icon="pi pi-ban"
            className="p-button-rounded p-button-danger"
            onClick={() => this.toggle('toggler2', rowData)}
            tooltip="Delete Permission"
            tooltipOptions={{ position: 'bottom' }}
          />
        </React.Fragment>
      );
    };

    const permissionColumns = [
      { field: 'email', header: 'Email' },
      { field: 'created_at', header: 'Date Created', body: dateBodyTemplate },
      { header: 'Action(s)', body: actionBodyTemplate },
    ];
    return (
      <div>
        <h2
          className="p-mb-3 p-text-bold"
          style={{ marginLeft: '20px', color: '#495057' }}
        >
          Manage User Permissions
        </h2>
        <br></br>
        <div className="p-grid p-justify-between cardFstyle">
          <div className="p-col-12 p-lg-6">
            <CardDemo
              title="Total admins"
              icon="pi pi-shopping-cart"
              content={this.props.booksize}
              color="#cae6fc"
              iconColor="#2196f3"
              update="1"
            ></CardDemo>
          </div>
          <div className="p-col-12 p-lg-6">
            <CardDemo
              title="Disabled admins"
              icon="pi pi-shopping-cart"
              color="#e7cbec"
              iconColor="#9c27b0"
              update="1"
              content={this.props.booksize - this.props.pagesize}
            ></CardDemo>
          </div>
        </div>
        <br></br>
        <div className="datatable-responsive-demo">
          <div>
            <DataTableDynamicDemo
              columns={permissionColumns}
              fetchFunction={this.props.fetchAdmins}
              tableHeader="Manage Admins"
              headData={this.headerData}
            />
          </div>
        </div>

        <Dialog
          draggable={false}
          visible={this.state['toggler']}
          header="Add New Adminsinistrator"
          modal
          className="p-fluid w-5"
          footer={this.adduserDialogFooter}
          onHide={this.handleClose}
        >
          <Formik
            validationSchema={AdminSchema}
            validateOnChange={true}
            initialValues={initialValues}
            onSubmit={values => {
              const postData = {
                email: values.email,
                password: values.password,
              };
              // console.log(postData);
              this.handleClose();
              this.props.createAdmin(postData);
            }}
          >
            {props => {
              const { handleChange, values, errors } = props;

              return (
                <>
                  <Form id="postform">
                    <div className="formgrid grid w-full">
                      <div className="field col-12">
                        <label htmlFor="staff_id" className="block font-normal">
                          Email
                        </label>
                        <InputText
                          id="email"
                          type="text"
                          name="email"
                          value={values.email}
                          placeholder="Email"
                          onChange={handleChange('email')}
                          tooltipOptions={{ position: 'bottom' }}
                          tooltip="User's email address"
                        />
                        <small>eg: john@mail.com</small>
                      </div>

                      <div className="field col-12">
                        <label htmlFor="state" className="block font-normal">
                          Password
                        </label>
                        <InputText
                          id="password"
                          type="text"
                          name="password"
                          value={values.password}
                          placeholder="Password"
                          onChange={handleChange('password')}
                          tooltipOptions={{ position: 'bottom' }}
                          tooltip="User's password"
                        />
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Dialog>
      </div>
    );
  }
}

PermissionsManagement.propTypes = {
  fetchAdmins: PropTypes.func.isRequired,
  createAdmin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  booksize: state.users.booksize,
  pagesize: state.users.pagesize,
});

export default connect(mapStateToProps, {
  fetchAdmins,
  createAdmin,
})(PermissionsManagement);

