import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { dateBodyTemplate } from './const';
import { Form, Formik } from 'formik';
import DataTableDynamicDemo from '../../../../shared/components/Table/AdTable';
import { InputTextarea } from 'primereact/inputtextarea';
import { UserSchema } from '../../../../shared/utils/validation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fetchTenants,
  deleteTenant,
} from '../../../../shared/redux/actions/tenantActions';
import {
  fetchUsers,
  createUserByAdmin,
  fetchTenantRoles,
} from '../../../../shared/redux/actions/userActions';
import CardDemo from '../../../../shared/components/card/CardDemo';
import { AutoComplete } from 'primereact/autocomplete';
import { RandomPassword } from '../../Admin/User/RandomPassword';

class Tenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggler: false,
      deleteToggler: false,
      createToggler: false,
      disableToggler: false,
      toggler2: false,
      info: [],
      title: '',
      description: '',
      selectedTenant: null,
      globalFilter: '',
      itemFilter: '',
      loading: false,
      items: [],
      catloading: false,
      userToggler: false,
      selectedRole: null,
      filteredRoles: null,
    };
    this.searchRole = this.searchRole.bind(this);

    this.toggle = this.toggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createNewTenant = this.createNewTenant.bind(this);
  }

  componentDidMount() {
    this.generatePwd();
  }
  searchRole(event) {
    setTimeout(() => {
      let filteredRoles;
      if (!event.query.trim().length) {
        filteredRoles = [...this.props.roles];
      } else {
        filteredRoles = this.props.roles.filter(option => {
          return option.title
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      this.setState({ filteredRoles });
      // console.log(filteredOptions);
    }, 250);
  }
  handleChange(evt, field) {
    this.setState({ [field]: evt.target.value });
  }

  async toggle(toggler, rowData) {
    let togglerStatus = this.state[toggler]; //check the status of the toggle you clicked
    var rowd = rowData;
    await localStorage.setItem('cat_id', rowData.id);
    this.props.fetchTenantRoles(rowData.scheme);

    return this.setState({
      [toggler]: !togglerStatus,
      info: rowData,
      rowd,
    });
  }

  handleOpen(toggler) {
    let togglerStatus = this.state[toggler]; //check the status of the toggle you clicked
    this.setState({
      [toggler]: !togglerStatus,
    });
  }

  handleClose() {
    this.setState({ toggler: false });
    this.setState({ toggler2: false });
    this.setState({ deleteToggler: false });
    this.setState({ disableToggler: false });
    this.setState({ createToggler: false });
    this.setState({ showlists: false });
    this.setState({ userToggler: false });
  }

  updateTenant() {
    const tenantPayload = {
      title: this.state.title || this.state.info.title,
      description: this.state.description || this.state.info.description,
    };
    const id = this.state.info.id;
    this.props.editTenant(id, tenantPayload);
  }

  deleteTenant() {
    const id = this.state.info.id;

    this.props.deleteTenant(id);
  }

  disableTenant() {
    const id = this.state.info.id;
    const tenantPayload = {
      status: 'false',
    };
    this.props.disableTenant(id, tenantPayload);
  }
  async createNewTenant() {
    return window.location.assign('/admin/tenant-form');
  }

  editTenantDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.updateTenant();
          this.handleClose();
        }}
      />
    </React.Fragment>
  );

  deleteTenantDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.deleteTenant();
          this.handleClose();
        }}
      />
    </React.Fragment>
  );
  disableTenantDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.deleteTenant();
          this.handleClose();
        }}
      />
    </React.Fragment>
  );

  createUserDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        form="postform"
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
      />
    </React.Fragment>
  );
  infoDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
    </React.Fragment>
  );

  headerData = (
    <React.Fragment>
      <Button
        label="Tenant"
        icon="pi pi-plus"
        className="p-button-raised p-button-outlined h-3rem"
        tooltipOptions={{ position: 'bottom' }}
        tooltip="Create New Tenant"
        onClick={() => this.createNewTenant()}
      />
    </React.Fragment>
  );
  generatePwd() {
    let password = new RandomPassword()
      .setLength(8)
      .setLowerCase(true)
      .setUpperCase(true)
      .setNumberCase(true)
      .generate();
    this.setState({ password });
  }
  render() {
    const initialValues = {
      first_name: '',
      last_name: '',
      middle_name: '',
      role_id: '',
      phone: '',
      email: '',
      password: this.state.password,
    };

    const actionBodyTemplate = rowData => {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-info"
            className="p-button-rounded p-button-info mr-2"
            onClick={() => this.toggle('toggler2', rowData)}
            tooltip="More Info"
            tooltipOptions={{ position: 'bottom' }}
          />
          &nbsp;
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-warning mr-2"
            onClick={() => this.toggle('toggler', rowData)}
            tooltip="Edit Tenant"
            tooltipOptions={{ position: 'bottom' }}
          />
          &nbsp;
          <Button
            icon="pi pi-user-plus"
            className="p-button-rounded p-button-success mr-2"
            onClick={() => this.toggle('userToggler', rowData)}
            tooltip="Add User To Tenant"
            tooltipOptions={{ position: 'bottom' }}
          />
          &nbsp;
          <Button
            icon="pi pi-ban"
            className="p-button-rounded p-button-danger  mr-2"
            onClick={() => this.toggle('disableToggler', rowData)}
            tooltip="Disable Tenant"
          />
        </React.Fragment>
      );
    };
    const tenantColumns = [
      { field: 'title', header: 'Tenant Name' },
      { field: 'sub_domain', header: 'Sub Domain' },

      {
        field: 'scheme',
        header: 'Tenant Id',
      },
      { field: 'created_at', header: 'Date Created', body: dateBodyTemplate },
      { header: 'Action(s)', body: actionBodyTemplate },
    ];

    return (
      <div>
        <h2
          className="p-mb-3 p-text-bold"
          style={{ marginLeft: '20px', color: '#495057' }}
        >
          Tenant info
        </h2>
        <br></br>

        <div className="p-grid p-justify-between cardFstyle">
          <div className="p-col-12 p-md-6 p-lg-3">
            <CardDemo
              title="Total Tenants"
              icon="pi pi-shopping-cart"
              color="#cae6fc"
              iconColor="#2196f3"
              update="1"
              content={this.props.booksize}
            ></CardDemo>
          </div>
          {/* <div className="p-col-12 p-md-6 p-lg-3">
            <CardDemo
              title="New Tenants"
              icon="pi pi-users"
              color="#e7cbec"
              iconColor="#9c27b0"
              update="1"
              content={this.props.pagesize}
            ></CardDemo>
          </div> */}
          <div className="p-col-12 p-md-6 p-lg-3">
            <CardDemo
              title="Active Tenants"
              icon="pi pi-user-minus"
              update="1"
              color="#fde0c2"
              iconColor="#f57c00"
              content={this.props.pagesize}
            ></CardDemo>
          </div>
        </div>
        <br></br>

        <div className="datatable-responsive-demo">
          <div>
            <DataTableDynamicDemo
              columns={tenantColumns}
              fetchFunction={this.props.fetchTenants}
              tableHeader="Manage Tenants"
              headData={this.headerData}
            />
          </div>
        </div>

        <Dialog
          draggable={false}
          visible={this.state['userToggler']}
          style={{ width: '40vw' }}
          header="Add User To Tenant"
          modal
          className="p-fluid"
          footer={this.createUserDialogFooter}
          onHide={this.handleClose}
        >
          <Formik
            validationSchema={UserSchema}
            validateOnChange={true}
            initialValues={initialValues}
            onSubmit={values => {
              const postData = {
                first_name: values.first_name,
                last_name: values.last_name,
                middle_name: values.middle_name,
                role_id: values.role_id.id,
                phone: values.phone,
                email: values.email,
                password: values.password,
              };
              const tenantKey = this.state.info.scheme;
              this.props.createUserByAdmin(postData, tenantKey);
              this.handleClose();
            }}
          >
            {props => {
              const { handleChange, values, errors } = props;
              return (
                <>
                  <Form id="postform">
                    <div className="formgrid grid">
                      <div className="field col-6">
                        <label htmlFor="state" className="block font-normal">
                          User Type
                        </label>
                        <AutoComplete
                          className="w-full"
                          dropdown
                          name="role_id"
                          id="role_id"
                          suggestions={this.state.filteredRoles}
                          completeMethod={this.searchRole}
                          field="title"
                          placeholder="Select usertype"
                          value={props.values.role_id}
                          onChange={selectedOption => {
                            let event = {
                              target: {
                                name: 'role_id',
                                value: selectedOption.target.value,
                              },
                            };
                            handleChange(event);
                          }}
                          tooltipOptions={{ position: 'bottom' }}
                          tooltip="User's user-type"
                          autoFocus
                        />
                        <small>eg: Head of Department</small>

                        <div className="error-message">{errors.role_id}</div>
                      </div>

                      <div className="field col-6">
                        <label htmlFor="staff_id" className="block font-normal">
                          Email
                        </label>
                        <InputText
                          id="staff_id"
                          type="text"
                          value={values.email}
                          placeholder="Email"
                          onChange={handleChange('email')}
                          tooltipOptions={{ position: 'bottom' }}
                          tooltip="User's email address"
                        />
                        <small>eg: john@mail.com</small>
                      </div>

                      <div className="field col-6">
                        <label htmlFor="state" className="block font-normal">
                          Password
                        </label>
                        <InputText disabled value={values.password} />
                      </div>
                      <div className="field col-6">
                        <label
                          htmlFor="firstname6"
                          className="block font-normal"
                        >
                          Last name
                        </label>
                        <InputText
                          id="firstname6"
                          type="text"
                          value={values.last_name}
                          placeholder="Last name"
                          onChange={handleChange('last_name')}
                          tooltipOptions={{ position: 'bottom' }}
                          tooltip="User's lastname"
                        />
                        <small>eg: Agyei</small>
                        <div className="error-message">{errors.last_name}</div>
                      </div>
                      <div className="field col-6">
                        <label
                          htmlFor="lastname6"
                          className="block font-normal"
                        >
                          First name
                        </label>
                        <InputText
                          id="lastname6"
                          type="text"
                          value={values.first_name}
                          onChange={handleChange('first_name')}
                          placeholder="First name"
                          tooltipOptions={{ position: 'bottom' }}
                          tooltip="User's firstname"
                        />
                        <small>eg: John</small>

                        <div className="error-message">{errors.first_name}</div>
                      </div>
                      <div className="field col-6">
                        <label htmlFor="email" className="block font-normal">
                          Middle name
                        </label>
                        <InputText
                          id="email"
                          type="text"
                          value={values.middle_name}
                          placeholder="Middle name"
                          onChange={handleChange('middle_name')}
                          tooltipOptions={{ position: 'bottom' }}
                          tooltip="User's middle name"
                        />
                        <small>eg: Ato</small>
                        <div className="error-message">
                          {errors.middle_name}
                        </div>
                      </div>

                      <div className="field col-6">
                        <label htmlFor="city" className="block font-normal">
                          Phone
                        </label>
                        <InputText
                          id="phone"
                          placeholder="0240000000"
                          maxLength={10}
                          value={values.phone}
                          onChange={handleChange('phone')}
                          tooltipOptions={{ position: 'bottom' }}
                          tooltip="User's phone number"
                        />
                        <small>eg: 0240000000</small>
                        <div className="error-message">{errors.phone}</div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Dialog>

        <Dialog
          draggable={false}
          visible={this.state['toggler']}
          style={{ width: '27vw' }}
          header="Edit Tenant Details"
          modal
          className="p-fluid"
          footer={this.editTenantDialogFooter}
          onHide={this.handleClose}
        >
          <div className="p-formgrid p-grid">
            <div className="p-field p-col">
              <label htmlFor="namefItem">Tenant name</label>
              <InputText
                id="title"
                name="title"
                defaultValue={this.state.info.title}
                onChange={event => this.handleChange(event, 'title')}
              />
            </div>
          </div>
          <div className="p-formgrid p-grid">
            <div className="p-field p-col">
              <label htmlFor="namefItem">Description</label>
              <InputTextarea
                id="description"
                name="description"
                defaultValue={this.state.info.description}
                onChange={event => this.handleChange(event, 'description')}
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          draggable={false}
          visible={this.state['deleteToggler']}
          style={{ width: '450px' }}
          header="Confirm Delete"
          modal
          footer={this.deleteTenantDialogFooter}
          onHide={this.handleClose}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: '2rem' }}
            />
            {this.state.info && (
              <span>
                Are you sure you want to delete <b>{this.state.info.title}</b>?
              </span>
            )}
          </div>
        </Dialog>
        <Dialog
          draggable={false}
          visible={this.state['disableToggler']}
          style={{ width: '450px' }}
          header="Confirm Delete"
          modal
          footer={this.disableTenantDialogFooter}
          onHide={this.handleClose}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: '2rem' }}
            />
            {this.state.info && (
              <span>
                Are you sure you want to delete <b>{this.state.info.title}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          draggable={false}
          visible={this.state['toggler2']}
          style={{ width: '60vw' }}
          header="Tenant Info"
          modal
          className="p-fluid"
          footer={this.infoDialogFooter}
          onHide={this.handleClose}
        >
          <div className="p-formgrid p-grid">
            <div className="p-field p-col">
              <label htmlFor="namefItem">Tenant name</label>
              <InputText value={this.state.info.title} disabled />
            </div>
            <div className="p-field p-col">
              <label htmlFor="namefItem">Description</label>
              <InputText value={this.state.info.description} disabled />
            </div>
          </div>
          <div className="datatable-responsive-demo">
            <div></div>
          </div>
        </Dialog>
      </div>
    );
  }
}
Tenant.propTypes = {
  fetchTenants: PropTypes.func.isRequired,
  tenants: PropTypes.array.isRequired,
  createUserByAdmin: PropTypes.func.isRequired,
  // editTenant: PropTypes.func.isRequired,
  // deleteTenant: PropTypes.func.isRequired,
  // createTenant: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tenants: state.tenants.tenants,
  roles: state.users.roles,
  booksize: state.tenants.booksize,
  pagesize: state.tenants.pagesize,
});

export default connect(mapStateToProps, {
  fetchTenants,
  fetchUsers,
  deleteTenant,
  createUserByAdmin,
  fetchTenantRoles,
  // editTenant,
  // deleteTenant,
  // createTenant,
  // fetchTenantItems,
})(Tenant);

