import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import Can from '../../../../shared/casl/can';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import {
  fetchSubscriptionsUnderPackage,
  createSubscription,
} from '../../../../shared/redux/actions/subscriptionActions';

import { fetchSubscriptionPackageInfo } from '../../../../shared/redux/actions/subPackageActions';
import { fetchAssets } from '../../../../shared/redux/actions/assetActions';
import TableUI from '../../../../shared/components/Table/Table';
import { priceBodyTemplate } from '../InventoryInfo/const';

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      invasset: [],
      toggler: false,
      addToggler: false,
      listToggler: false,
      deleteToggler: false,
      infoToggler: false,
      globalFilter: '',
      modalFilter: '',
      info: [],
      infoinv: [],
      loading: false,
    };
  }
  async componentDidMount() {
    await this.props.fetchSubscriptionPackageInfo(localStorage.package_id);
    return this.props.invdetails;
  }

  toggle(toggler, rowData) {
    let togglerStatus = this.state[toggler]; //check the status of the toggle you clicked
    this.setState({
      [toggler]: !togglerStatus,
      info: rowData,
    });
  }
  handleOpen(toggler) {
    let togglerStatus = this.state[toggler]; //check the status of the toggle you clicked
    this.setState({
      [toggler]: !togglerStatus,
    });
  }
  handleClose() {
    this.setState({ addToggler: false });
    this.setState({ toggler2: false });
    this.setState({ listToggler: false });
    this.setState({ deleteToggler: false });
    this.setState({ infoToggler: false });
  }

  listItemDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
    </React.Fragment>
  );
  itemInfoDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.setState({ infoToggler: false })}
      />
    </React.Fragment>
  );
  addItemDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.setState({ addToggler: false })}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.addItemToPackage();
          this.handleClose();
        }}
      />
    </React.Fragment>
  );

  deleteItemDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => this.handleClose()}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          this.delfromInventory();
          this.handleClose();
        }}
      />
    </React.Fragment>
  );
  addItemToPackage() {
    const payload = {
      asset_id: this.state.info.id,
      package_id: localStorage.package_id,
      price: this.state.info.price,
    };
    this.props.createSubscription(payload);
  }
  async fetchAssetDetails(rowData) {
    await localStorage.setItem('assetid', rowData.id);

    return this.props.history.push('/assetinfo');
  }

  deletefromPackage() {
    const id = localStorage.package_id;
    const itemid = this.state.info.id;

    console.log(itemid, id);
    this.props.deletefromPackage(itemid, id);
  }

  render() {
    const listActionTemplate = rowData => {
      return (
        <React.Fragment>
          <div className="p-d-flex">
            {/* <Can do="view" on="Inventory">
            <Button
              icon="pi pi-info"
              className="p-button-rounded p-button-info p-mr-2"
             onClick={() => this.toggle("infoToggler", rowData)}
              tooltip="More info"
            />
          </Can> */}

            <Can do="edit" on="Subscriptions">
              <Button
                icon="pi pi-plus"
                className="p-button-rounded p-button-success  "
                onClick={() => this.toggle('addToggler', rowData)}
                tooltip="Add Asset"
                tooltipOptions={{ position: 'bottom' }}
              />
            </Can>
          </div>
        </React.Fragment>
      );
    };
    const actionBodyTemplate = rowData => {
      return (
        <React.Fragment>
          <div className="p-d-flex">
            <Can do="view" on="Subscriptions">
              <Button
                icon="pi pi-info"
                className="p-button-rounded p-button-info mr-2"
                onClick={() => this.fetchAssetDetails(rowData)}
                tooltip="More info"
                tooltipOptions={{ position: 'bottom' }}
              />
            </Can>

            <Can do="edit" on="Subscriptions">
              <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger  "
                onClick={() => this.toggle('deleteToggler', rowData)}
                tooltip="Remove Asset"
                tooltipOptions={{ position: 'bottom' }}
              />
            </Can>
          </div>
        </React.Fragment>
      );
    };
    const addColumns = [
      { field: 'title', header: 'Asset Name' },
      { field: 'code', header: 'Asset Code' },
      {
        field: 'serial_number',
        header: 'Serial Number',
      },
      // { field: 'amount', header: 'Price', body: priceBodyTemplate },
      { header: 'Actions', body: listActionTemplate },
    ];
    const listColumns = [
      { field: 'asset.title', header: 'Asset Name' },
      {
        field: 'asset.serial_number',
        header: 'Serial Number',
      },
      { field: 'asset.model', header: 'Asset Model' },

      // { field: 'amount', header: 'Price', body: priceBodyTemplate },
      { header: 'Actions', body: actionBodyTemplate },
    ];
    return (
      <div>
        <h2
          className="p-mb-3 p-text-bold"
          style={{ marginLeft: '20px', color: '#495057' }}
        >
          Subscriptions Under Package
        </h2>
        <br></br>
        <div
          style={{ marginLeft: '10px', fontSize: '20px', fontWeight: 'bold' }}
        >
          <div className="field col">
            <label htmlFor="firstname6" className="block font-normal">
              Package name : {this.props.packageInfo.title}
            </label>
          </div>
          <div className="field col">
            <label htmlFor="firstname6" className="block font-normal">
              Service Provider Contact :{' '}
              {this.props.packageInfo.service_owner_phone}
            </label>
          </div>
          <div className="field col">
            <label htmlFor="firstname6" className="block font-normal">
              Service Provider Email :{' '}
              {this.props.packageInfo.service_owner_email}
            </label>
          </div>
          <div className="field col">
            <label htmlFor="firstname6" className="block font-normal">
              Service Provider Website :{' '}
              {this.props.packageInfo.service_owner_url}
            </label>
          </div>
        </div>
        <Divider style={{ width: '82vw' }} />

        <div className="datatable-responsive-demo">
          <TableUI
            columns={listColumns}
            fetchFunction={this.props.fetchSubscriptionsUnderPackage}
            clickFunction={() => this.handleOpen('listToggler')}
            tableHeader="Subscribed Items"
            style={{
              width: '76vw',
              marginLeft: '15px',
              marginBottom: '0px',
              marginTop: '0px',
            }}
          />
        </div>

        <Dialog
          draggable={false}
          visible={this.state['listToggler']}
          style={{ width: '60vw' }}
          modal
          className="p-fluid"
          footer={this.listItemDialogFooter}
          onHide={() => this.handleClose()}
          header="Add Assets To Subscription Package"
        >
          <div className="datatable-responsive-demo">
            <TableUI
              columns={addColumns}
              fetchFunction={this.props.fetchAssets}
              tableHeader="List of Assets"
            />
          </div>
        </Dialog>
        <Dialog
          draggable={false}
          visible={this.state['addToggler']}
          style={{ width: '40vw' }}
          header="Add Asset to Subscription"
          modal
          className="p-fluid"
          footer={this.addItemDialogFooter}
          onHide={() => this.setState({ addToggler: false })}
        >
          <div className="formgrid grid">
            <div className="field col-12">
              <label htmlFor="departmentName" className="block font-normal">
                Asset name
              </label>
              <InputText
                id="title"
                title="title"
                defaultValue={this.state.info.title}
                disabled
              />
            </div>
            <div className="field col-12">
              <label htmlFor="departmentName" className="block font-normal">
                Asset code
              </label>
              <InputText
                id="title"
                title="title"
                defaultValue={this.state.info.code}
                disabled
              />
            </div>
            <div className="field col-12">
              <label htmlFor="departmentName" className="block font-normal">
                Serial number
              </label>
              <InputText
                id="title"
                title="title"
                defaultValue={this.state.info.serial_number}
                disabled
              />
            </div>
            <div className="field col-12">
              <label htmlFor="departmentName" className="block font-normal">
                Price
              </label>
              <InputText
                id="title"
                title="title"
                defaultValue={this.state.info.price}
                disabled
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          draggable={false}
          visible={this.state['deleteToggler']}
          style={{ width: '450px' }}
          header="Confirm Removal"
          modal
          footer={this.deleteItemDialogFooter}
          onHide={this.handleClose}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-2"
              style={{ fontSize: '2rem' }}
            />
            {this.state.info && (
              <span>
                Are you sure you want to remove <b>{this.state.info.title}</b>?
              </span>
            )}
          </div>
        </Dialog>
      </div>
    );
  }
}

Subscriptions.propTypes = {};

const mapStateToProps = state => ({
  packageInfo: state.subscriptionPackages.subscriptionPackageInfo,
});
export default connect(mapStateToProps, {
  fetchAssets,
  fetchSubscriptionPackageInfo,
  fetchSubscriptionsUnderPackage,
  createSubscription,
})(Subscriptions);

