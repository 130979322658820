/* eslint-disable no-underscore-dangle */
import { Ability, AbilityBuilder } from '@casl/ability';
import store from '../redux/store';

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });

store.subscribe(() => {
  let auth = store.getState().auth;
  ability.update(defineRulesFor(auth));
});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const defineRulesFor = auth => {
  // const permissions = auth.role;
  const derivedPermissions = auth.permissions;
  // console.log('derivedPermissions', derivedPermissions);

  const { can, rules } = new AbilityBuilder();
  if (derivedPermissions) {
    derivedPermissions.forEach(permission => {
      can(permission.op, capitalizeFirstLetter(permission.content_type));
    });
  }

  // console.log(rules);
  return rules;
};

export default ability;

