import { dashboardActions, reportActions } from '../actions/types';

const initialState = {
  dashitems: [], ///all fetched assets
  report: [],
  pagesize: '',
  booksize: '',
  dashAssets: [],
  dashInventory: [],
  dashRequests: [],
  dashProposals: [],
  dashAssetsTotal: [],
  dashAssetsAssigned: [],
  dashAssetsDecom: [],
  dashInventoryTotal: [],
  dashInventoryActive: [],
  dashInventoryInActive: [],
  dashProposalsPending: [],
  dashProposalsTotal: [],
  dashProposalsApproved: [],
  dashRequestsTotal: [],
  dashRequestsPending: [],
  dashRequestsApproved: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardActions.FETCH_DASH_SUCCESS:
      return {
        ...state,
        dashitems: action.payload,
        dashAssets: action.payload.asset,
        dashAssetsTotal: action.payload.asset.total[0],
        dashAssetsAssigned: action.payload.asset.assigned[0],
        dashAssetsDecom: action.payload.asset.decomissioned[0],

        dashInventory: action.payload.inventory,
        dashInventoryTotal: action.payload.inventory.total[0],
        dashInventoryActive: action.payload.inventory.active[0],
        dashInventoryInActive: action.payload.inventory.inactive[0],

        dashProposals: action.payload.proposal,
        dashProposalsPending: action.payload.proposal.pending[0],
        dashProposalsTotal: action.payload.proposal.total[0],
        dashProposalsApproved: action.payload.proposal.approved[0],

        dashRequests: action.payload.request,
        dashRequestsTotal: action.payload.request.total[0],
        dashRequestsPending: action.payload.request.pending[0],
        dashRequestsApproved: action.payload.request.approved[0],
      };

    case reportActions.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;

