import { combineReducers } from 'redux';
import assetReducer from './assetReducer';
import categoryReducer from './categoryReducer';
import departmentReducer from './departmentReducer';
import inventoryReducer from './inventoryReducer';
import recommendationReducer from './recommendationReducer';
import requestReducer from './requestReducer';
import userReducer from './userReducer';
import authReducer from './authReducer';
import vendorReducer from './vendorReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import countryReducer from './countryReducer';
import subcountryReducer from './subcountryReducer';
import cityReducer from './cityReducer';
import locationReducer from './locationReducer';
import tenantReducer from './tenantReducer';
import dashboardReducer from './dashboardReducer';
import manufacturerReducer from './manufacturerReducer';
import consumableReducer from './consumablesReducer';
import currencyReducer from './currencyReducer';
import subscriptionReducer from './subscriptionReducer';
import subscriptionPackageReducer from './subPackageReducer';
import configurationReducer from './envReducer';
import filesReducer from './filesReducer';
import activityReducer from './activityReducer';

const persistConfig = {
  key: 'root',
  storage,

  whitelist: ['auth', 'tenants'],
};
const allReducers = combineReducers({
  assets: assetReducer,
  categories: categoryReducer,
  inventories: inventoryReducer,
  requests: requestReducer,
  recommendations: recommendationReducer,
  users: userReducer,
  departments: departmentReducer,
  vendors: vendorReducer,
  auth: authReducer,
  countries: countryReducer,
  subcountries: subcountryReducer,
  cities: cityReducer,
  locations: locationReducer,
  tenants: tenantReducer,
  dashboard: dashboardReducer,
  manufacturers: manufacturerReducer,
  consumables: consumableReducer,
  currencies: currencyReducer,
  subscriptions: subscriptionReducer,
  subscriptionPackages: subscriptionPackageReducer,
  configurations: configurationReducer,
  fileUploads: filesReducer,
  activities: activityReducer,
});

export default persistReducer(persistConfig, allReducers);

