import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { ResetSchema } from '../../../../shared/utils/validation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { passwordReset } from '../../../../shared/redux/actions/authActions';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useLocation } from 'react-router-dom';

function ResetPassword(props) {
  const bgImg = process.env.PUBLIC_URL + 'default_bg.jpg';
  let location = useLocation();
  let token = location.search.split('?token=')[1];

  return (
    <div className="bg-grad w-full h-screen flex">
      <div className=" flex shadow-6 login-box ">
        <div className="w-6 bd-rad bg-white p-2">
          <div className="w-10 align-self-center flex align-items-center my-auto mx-auto">
            <Formik
              initialValues={{
                password: '',
                passwordConfirmation: '',
              }}
              validationSchema={ResetSchema}
              onSubmit={(values, setSubmitting) => {
                const formData = {
                  password: values.password,
                };

                props.passwordReset(token, { ...formData });
                setSubmitting(false);
              }}
            >
              {props => {
                const {
                  handleChange,
                  values,
                  errors,
                  status,
                  touched,
                  isSubmitting,
                } = props;
                return (
                  <div className="flex w-full h-full align-items-center justify-content-center">
                    <div className="align-items-center justify-content-center md:px-3 md:py-3   border-round w-9 mt-3 ">
                      <div className="text-5xl mb-2">
                        <span>Reset Your Password</span>
                      </div>
                      <div className="text-md text-500 mb-3 lg:mb-6 ">
                        <span>Please enter new credentials</span>
                      </div>
                      <div className="text-center text-600 text-md font-medium mb-1">
                        E-ASSET MANAGEMENT SYSTEM
                      </div>
                      <Form>
                        <div className="password mt-3">
                          <label
                            htmlFor="password"
                            className="block text-700 font-medium mb-2"
                          >
                            Enter New Password
                          </label>
                          <Field name="password">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <div>
                                <InputText
                                  type="password"
                                  name="password"
                                  className="w-full mb-1"
                                  placeholder="New Password"
                                  onChange={event =>
                                    handleChange(event, 'password')
                                  }
                                  {...field}
                                />

                                {meta.touched && meta.error && (
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="error-message"
                                  />
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="password">
                          <label
                            htmlFor="passwordConfirmation"
                            className="block text-700 font-medium mb-2"
                          >
                            Confirm Password
                          </label>
                          <Field name="passwordConfirmation">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <div>
                                <InputText
                                  type="password"
                                  name="passwordConfirmation"
                                  className="w-full mb-1"
                                  placeholder="Confirm Password"
                                  onChange={event =>
                                    handleChange(event, 'passwordConfirmation')
                                  }
                                  {...field}
                                />

                                {meta.touched && meta.error && (
                                  <ErrorMessage
                                    name="passwordConfirmation"
                                    component="div"
                                    className="error-message"
                                  />
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="submit">
                          <Button
                            label="Reset Password"
                            icon="pi pi-lock"
                            type="submit"
                            className="w-full"
                            disabled={isSubmitting}
                            style={{ background: '#1e3a8a' }}
                            loading={isSubmitting}
                          />
                        </div>
                        {/* <div>
                          {isSubmitting && (
                            <img
                              alt="loading.."
                              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                            />
                          )}
                        </div> */}
                        {status && (
                          <div className={'alert alert-danger'}>{status}</div>
                        )}
                      </Form>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
          <div className="text-xs align-content-center justify-content-center font-italic flex -mt-2">
            <p className="flex">Powered by:</p>
            <div className="flex ">
              <img
                src={process.env.PUBLIC_URL + 'aiti-logo.png'}
                alt="logo"
                // height={50}
                className=" w-6rem h-2rem "
              />
            </div>
          </div>
        </div>
        <div
          className="w-6 p-0 h-full bd-rad  bg-no-repeat bg-cover flex align-items-end "
          style={{
            backgroundImage: `url("${bgImg}")`,
          }}
        >
          <div className="transbox"></div>
        </div>
      </div>
    </div>
  );
}

ResetPassword.propTypes = {
  passwordReset: PropTypes.func.isRequired,
};

export default connect(null, { passwordReset })(ResetPassword);

